export class System {
  constructor(name, options) {
    this.type = 'OA'; // might be 'OA' = WinCC OA Server, 'RP' = Remote Para,
    //          'ODB' = Oracle DB Server, 'IDB' = Influx DB Server

    this.uilight = 0;
    this.uiclient = 0;
    this.proto_std = 0;
    this.proto_prem = 0;

    this.redundant = false;
    this.sysios = 0;
    this.subios = 0;
    this.licios = 0;
    //bacnet
    this.sysbacnets = 0;
    this.subbacnets = 0;
    this.licbacnets = 0;
    this.historian = 'hdb';
    this.dblic = 'asfu';
    this.videobase = false;
    this.videolight = false;
    this.videodisplaydist = false;
    this.s7 = 8;
    this.num = 1;
    this.id = cuid();
    Object.assign(this, options);
    this.name = name;
    if (!name || name === '') {
      name = System.getNewSystemName();
    }
    this.notes = {};
    this.calcIOs();
    this.calcBacnets();
  }

  static getMax(m, bus) {
    let max = m;
    bus.partners.map(system => {
      const r = system.name.match(/S(\d+)/);
      if (r && r[1]) {
        const i = parseInt(r[1], 10);
        if (i > max) {
          max = i;
        }
      }
    });
    if (bus.busses && bus.busses.length > 0) {
      bus.busses.map(b => {
        max = System.getMax(max, b);
      });
    }
    return max;
  }

  static getNewSystemName() {
    if (!window.app.architecture) {
      window.app.architecture = { busses: [], oaVersion: window.app.architecture.oaVersion };
    }
    let max = 0;
    window.app.architecture.busses.map(item => {
      max = System.getMax(max, item);
    });
    return `S${(parseInt(max) + 1).toString()}`;
  }

  static getSys(bus, num) {
    if (bus.partners) {
      bus.partners.map(p => {
        p.sysanz = (p.num || 1) * num;
      });
    }
    const ret = [...bus.partners] || [];
    if (bus.busses) {
      bus.busses.map(b => {
        ret.push(...System.getSys(b, (b.num || 1) * num));
      });
    }
    return ret;
  }

  static getAllSystems() {
    const ret = [];
    if (!window.app.architecture) {
      window.app.architecture = { busses: [], oaVersion: window.app.architecture.oaVersion };
    }
    const systems = [];
    window.app.architecture.busses.map(item => {
      const r = System.getSys(item, item.num || 1);
      r.map(s => ret.push(s));
    });
    return ret;
  }

  static getSystemById(id) {
    let ret = null;
    const rf = (bus, id) => {
      if (bus.partners) {
        bus.partners.forEach(p => {
          if (p.id === id) {
            ret = p;
            return p;
          }
        });
      }

      if (bus.busses && !ret) {
        bus.busses.forEach(b => {
          const s = rf(b, id);
          if (s) {
            ret = s;
            return s;
          }
        });
      }
      return ret;
    };

    window.app.architecture.busses.forEach(b => {
      const s = rf(b, id);
      if (s) {
        ret = s;
        return s;
      }
    });
    return ret;
  }

  static getLicIOs(num, type) {
    if (['OA_IPC128', 'OA_IPC256', 'OA_IPC512', 'OA_IPC2048', 'OA_IPC4096'].includes(type)) {
      return num;
    }
    const staffel = [
      0, 1000, 3000, 5000, 10000, 15000, 25000, 50000, 75000, 100000, 150000, 200000, 250000,
      99999999999,
    ];
    let erg = 0;
    for (let i = 0; i < staffel.length - 1; i += 1) {
      if (staffel[i] >= num) {
        erg = staffel[i];
        return erg;
      }
    }
    if (erg === 0) {
      erg = staffel[staffel.length - 1];
    }
    return erg;
  }

  static getLicBacnets(num) {
    let licensedBacnets = 0;
    const bacnetList = System.calcBacnet(num, '');
    bacnetList.forEach(e => {
      licensedBacnets += e.amount * e.count;
    });
    return licensedBacnets;
  }

  static getSubIOs(bus, mainSystem) {
    let ios = 0;
    if (bus) {
      if (bus.partners && bus.partners.length > 0) {
        bus.partners.map(i => {
          if (
            ['OA', 'OA_IPC128', 'OA_IPC256', 'OA_IPC512', 'OA_IPC2048', 'OA_IPC4096'].includes(
              i.type,
            ) &&
            i.dist
          ) {
            if (i !== mainSystem) {
              ios += System.getLicIOs(i.sysios || 0, i.type) * (i.num || 1);
            } else {
              ios += System.getLicIOs(i.sysios || 0, i.type) * ((i.num || 1) - 1);
            }
          }
        });
      }
      if (bus.busses && bus.busses.length > 0) {
        bus.busses.map(b => {
          ios += (System.getSubIOs(b, mainSystem) || 0) * (b.num || 1);
        });
      }
    }
    return ios;
  }

  static getSubBacnets(bus, mainSystem) {
    let ios = 0;
    if (bus) {
      if (bus.partners && bus.partners.length > 0) {
        bus.partners.map(i => {
          if (
            ['OA', 'OA_IPC128', 'OA_IPC256', 'OA_IPC512', 'OA_IPC2048', 'OA_IPC4096'].includes(
              i.type,
            ) &&
            i.dist
          ) {
            if (i !== mainSystem) {
              ios += System.getLicBacnets(i.sysbacnets || 0) * (i.num || 1);
            } else {
              ios += System.getLicBacnets(i.sysbacnets || 0) * ((i.num || 1) - 1);
            }
          }
        });
      }
      if (bus.busses && bus.busses.length > 0) {
        bus.busses.map(b => {
          ios += (System.getSubBacnets(b, mainSystem) || 0) * (b.num || 1);
        });
      }
    }
    return ios;
  }

  static getMLFBEntry(mlfb, cnt) {
    if (!window.app.pricelist[mlfb]) {
      console.log(`mlfb not found in pricelist: ${mlfb}`);
    }
    return {
      mlfb,
      cnt,
      data: window.app.pricelist[mlfb],
    };
  }

  static getStaffel(num, mult, staffel, mlfb) {
    let rest = num;
    const ret = [];
    let i = staffel.length - 1; // Starte mit der höchsten Staffel

    while (rest > 0 && i >= 0) {
      if (rest >= staffel[i]) {
        const anz = Math.floor(rest / staffel[i]);
        rest -= anz * staffel[i]; // Aktualisiere den Rest nach Abzug der gefundenen Staffel
        ret.push({ st: staffel[i], anz: mult * anz, mlfb: mlfb[i] });
      }
      i -= 1; // Gehe zur nächsten kleineren Staffel
    }

    return ret;
  }

  disableDist() {
    this.dist = false;
  }

  enableDist() {
    this.dist = true;
  }

  setType(type) {
    this.type = type;

    // update the sysios
    switch (type) {
      case 'OA_IPC128':
        this.sysios = 128;
        this.sysbacnets = 200;
        break;
      case 'OA_IPC256':
        this.sysios = 256;
        this.sysbacnets = 200;

        break;
      case 'OA_IPC512':
        this.sysios = 512;
        this.sysbacnets = 200;

        break;
      case 'OA_IPC2048':
        this.sysios = 2048;
        this.sysbacnets = 200;

        break;
      case 'OA_IPC4096':
        this.sysios = 4096;
        this.sysbacnets = 200;
        break;
      default:
        this.sysios = 0;
    }

    // recalc ios
    this.calcIOs();
    this.calcBacnets();
  }

  setBus(bus) {
    this.bus = bus;
  }

  setNum(num) {
    this.num = num;
    this.calcIOs();
  }

  toggleDrs(drsValue) {
    this.drs = drsValue;
    if (drsValue === true) {
      this.redundant = true;
      this.dist = true;
      this.historian = 'rdb';
    }
  }

  setUlcUx(ulcux) {
    this.ulcux = ulcux;
    if (this.ulcux > 30) {
      this.loadbalancer = Math.ceil(this.ulcux / 30) - 1;
    }
  }

  calcIOs() {
    this.subios = System.getSubIOs(this.bus, this);
    this.licios = System.getLicIOs(this.sysios + (this.subios ? this.subios : 0), this.type);
    this.numios = this.sysios + this.subios || 0;
  }

  calcBacnets() {
    this.subbacnets = System.getSubBacnets(this.bus, this);
    this.licbacnets = System.getLicBacnets(this.sysbacnets + this.subbacnets);
    this.numbacnets = this.sysbacnets + this.subbacnets || 0;
  }

  setSysIos(sysios) {
    this.sysios = sysios;
    this.calcIOs();
  }

  setSysBacnets(sysios) {
    this.sysbacnets = sysios;
    this.calcBacnets();
  }

  getLicIOMLFB(pref) {
    const staffel = [
      1000, 3000, 5000, 10000, 15000, 25000, 50000, 75000, 100000, 150000, 200000, 250000,
      99999999999,
    ];
    const iomlfb = [
      `${pref}BB0`,
      `${pref}BB1`,
      `${pref}BB2`,
      `${pref}BB3`,
      `${pref}BB4`,
      `${pref}BB5`,
      `${pref}BB6`,
      `${pref}BB7`,
      `${pref}BB8`,
      `${pref}BC0`,
      `${pref}BC1`,
      `${pref}BC2`,
      `${pref}BC3`,
    ];
    const licio = System.getLicIOs(this.sysios + (this.subios ? this.subios : 0), this.type);
    return licio === 0 ? null : iomlfb[staffel.findIndex(ele => ele === licio)];
  }

  findNextIndex(array, value) {
    const index = array.indexOf(value);
    return index + 1;
  }
  static calcBacnet(amountBacnets, pref) {
    const ret = [];
    //bacnet
    if (amountBacnets === 0) {
      return [];
    }
    let bacnetStaffelMlfBs = [
      { amount: 200, mlfb: pref + 'DL1' },
      { amount: 500, mlfb: pref + 'DL2' },
      { amount: 1000, mlfb: pref + 'DL3' },
      { amount: 3000, mlfb: pref + 'DL4' },
      { amount: 5000, mlfb: pref + 'DL5' },
    ];
    if (amountBacnets <= 5000) {
      let packageFound = false;
      //regel: finde nächst größeres paket
      bacnetStaffelMlfBs.forEach(e => {
        if (e.amount >= amountBacnets && packageFound === false) {
          packageFound = true;
          ret.push({ mlfb: e.mlfb, count: 1, amount: e.amount });
        }
      });
    } else if (amountBacnets > 5000 && amountBacnets <= 11000) {
      const quotient = Math.floor(amountBacnets / 5000);
      const rest = amountBacnets % 5000;

      //add the 5000er packages
      ret.push({ mlfb: pref + 'DL5', count: quotient, amount: 5000 });

      if (rest > 0) {
        //regel: finde nächst größeres paket
        let packageFound = false;
        bacnetStaffelMlfBs.forEach(e => {
          if (e.amount >= rest && packageFound === false) {
            packageFound = true;
            ret.push({ mlfb: e.mlfb, count: 1, amount: e.amount });
          }
        });
      }
    } else if (amountBacnets > 11000 && amountBacnets <= 30000) {
      ret.push({ mlfb: pref + 'DL6', count: 1, amount: 30000 });
    } else if (amountBacnets > 30000) {
      ret.push({ mlfb: pref + 'DL7', count: 1, amount: 99999999999 });
    }
    return ret;
  }
  systemToMLFB(license, sus) {
    let servercnt = 1;
    const ret = [];
    this.calcIOs();
    this.calcBacnets();

    let lpref = '';
    let spref = '';
    const pref = [];
    const pricetype = [];
    if (license === 'lic') {
      lpref = '6AV6355-1AA50-0';
    }
    if (license === 'upg') {
      lpref = '6AV6355-1CA50-0';
    }
    if (sus === 'sus') {
      spref = '6AV6355-1DA00-0';
    }
    if (sus === 'sms') {
      spref = '6AV6355-1FA00-0';
    }
    if (sus === 'pos') {
      spref = '6AV6355-1GA00-0';
    }
    if (lpref !== '') {
      pref.push(lpref);
      pricetype.push(license);
    }
    if (spref !== '') {
      pref.push(spref);
      pricetype.push(sus);
    }

    if (this.redundant) {
      servercnt = 2;
    }
    for (let i = 0; i < pref.length; i += 1) {
      if (this.type === 'OA_IPC128') {
        ret.push(System.getMLFBEntry(`${pref[i]}FA4`, servercnt));
      }
      if (this.type === 'OA_IPC256') {
        ret.push(System.getMLFBEntry(`${pref[i]}FA0`, servercnt));
      }
      if (this.type === 'OA_IPC512') {
        ret.push(System.getMLFBEntry(`${pref[i]}FA1`, servercnt));
      }
      if (this.type === 'OA_IPC2048') {
        ret.push(System.getMLFBEntry(`${pref[i]}FA2`, servercnt));
      }
      if (this.type === 'OA_IPC4096') {
        ret.push(System.getMLFBEntry(`${pref[i]}FA3`, servercnt));
      }

      if (this.type === 'OA') {
        ret.push(System.getMLFBEntry(`${pref[i]}BA0`, servercnt));
      }
      if (this.type === 'apm') {
        ret.push(System.getMLFBEntry(`${pref[i]}HB2`, servercnt));
      }

      if (this.type === 'OA' || this.type === 'apm' || this.type === 'OA_IPC4096') {
        //bacnet here
        const bacnetRows = System.calcBacnet(this.sysbacnets + this.subbacnets, pref[i]);
        if (bacnetRows) {
          bacnetRows.forEach(e => {
            ret.push(System.getMLFBEntry(e.mlfb, e.count * servercnt));
          });
        }

        const lic = this.getLicIOMLFB(pref[i]);

        // add only oa specific options

        if (this.type === 'OA') {
          if (lic) {
            ret.push(System.getMLFBEntry(lic, servercnt));
          }
          if (this.s7 === '64') {
            ret.push(System.getMLFBEntry(`${pref[i]}DA1`, servercnt));
          }
          if (this.s7 === '512') {
            ret.push(System.getMLFBEntry(`${pref[i]}DA2`, servercnt));
          }
        }

        if (this.redundant) {
          ret.push(System.getMLFBEntry(`${pref[i]}EF0`, servercnt));
        }
        if (this.apm) {
          ret.push(System.getMLFBEntry(`${pref[i]}HB0`, servercnt));
        }

        if (this.loadbalancer) {
          ret.push(System.getMLFBEntry(`${pref[i]}ET1`, this.loadbalancer * servercnt));
        }
        if (this.websocket) {
          ret.push(System.getMLFBEntry(`${pref[i]}ET2`, this.websocket * servercnt));
        }
        if (this.ngaconnections) {
          ret.push(System.getMLFBEntry(`${pref[i]}EA4`, this.ngaconnections * servercnt));
        }
        if (this.dist) {
          ret.push(System.getMLFBEntry(`${pref[i]}EE0`, servercnt));
        }
        if (this.drs) {
          ret.push(System.getMLFBEntry(`${pref[i]}EF1`, servercnt));
        }

        if (parseInt(this.uiclient) <= 31) {
          const uiclient = System.getStaffel(
            parseInt(this.uiclient),
            servercnt,
            [1, 10, 25],
            !this.redundant
              ? [`${pref[i]}CN0`, `${pref[i]}CN1`, `${pref[i]}CN2`]
              : [`${pref[i]}CP0`, `${pref[i]}CP1`, `${pref[i]}CP2`],
          );
          if (uiclient.length && uiclient.length > 0) {
            uiclient.map(ui => ret.push(System.getMLFBEntry(ui.mlfb, ui.anz)));
          }
        } else {
          ret.push(
            System.getMLFBEntry(!this.redundant ? `${pref[i]}CN3` : `${pref[i]}CP3`, servercnt),
          );
        }

        if (parseInt(this.uilight) <= 31) {
          const uilight = System.getStaffel(
            parseInt(this.uilight),
            servercnt,
            [1, 10, 25],
            !this.redundant
              ? [`${pref[i]}CQ0`, `${pref[i]}CQ1`, `${pref[i]}CQ2`]
              : [`${pref[i]}CR0`, `${pref[i]}CR1`, `${pref[i]}CR2`],
          );
          if (uilight.length && uilight.length > 0) {
            uilight.map(ui => ret.push(System.getMLFBEntry(ui.mlfb, ui.anz)));
          }
        } else {
          ret.push(
            System.getMLFBEntry(!this.redundant ? `${pref[i]}CQ3` : `${pref[i]}CR3`, servercnt),
          );
        }

        if (this.para > 0) {
          ret.push(
            System.getMLFBEntry(
              !this.redundant ? `${pref[i]}CH0` : `${pref[i]}CH1`,
              this.para * servercnt,
            ),
          );
        }

        if (parseInt(this.proto_std) > 0) {
          ret.push(System.getMLFBEntry(`${pref[i]}DN0`, this.proto_std * servercnt));
        }

        if (parseInt(this.proto_prem) > 0) {
          ret.push(System.getMLFBEntry(`${pref[i]}DN1`, this.proto_prem * servercnt));
        }
        if (this.historian === 'rdb') {
          ret.push(System.getMLFBEntry(`${pref[i]}EA0`, servercnt));
        }
        if (this.historian === 'nga') {
          if (this.numios > 150000) {
            ret.push(System.getMLFBEntry(`${pref[i]}EA3`, servercnt));
          } else {
            ret.push(System.getMLFBEntry(`${pref[i]}EA2`, servercnt));
          }
        }

        if (this.sinaut === '50') {
          ret.push(System.getMLFBEntry(`${pref[i]}DF0`, servercnt));
        }
        if (this.sinaut === '250') {
          ret.push(System.getMLFBEntry(`${pref[i]}DF1`, servercnt));
        }

        if (this.cerberus) {
          ret.push(System.getMLFBEntry(`${pref[i]}DH0`, servercnt));
        }

        /**
         if (parseInt(this.sysbacnets, 0) > 0) {
         ret.push(System.getMLFBEntry(`${pref[i]}EB0`, servercnt));
         }
         **/

        if (parseInt(this.webserver) > 0) {
          ret.push(System.getMLFBEntry(`${pref[i]}ET0`, this.webserver * servercnt));
        }

        if (this.commcenter) {
          ret.push(System.getMLFBEntry(`${pref[i]}EC4`, servercnt));
        }

        if (this.customcomp > 0) {
          ret.push(
            System.getMLFBEntry(
              !this.redundant ? `${pref[i]}ED2` : `${pref[i]}ED3`,
              this.customcomp * servercnt,
            ),
          );
        }
        if (this.dblogger) {
          ret.push(System.getMLFBEntry(`${pref[i]}EA1`, servercnt));
        }
        if (this.gismap) {
          ret.push(System.getMLFBEntry(`${pref[i]}EH5`, servercnt));
        }

        if (this.topology) {
          ret.push(System.getMLFBEntry(`${pref[i]}HA0`, servercnt));
        }
        if (this.opcuasrv) {
          ret.push(System.getMLFBEntry(`${pref[i]}EK0`, servercnt));
        }
        if (this.report) {
          ret.push(
            System.getMLFBEntry(!this.redundant ? `${pref[i]}EN5` : `${pref[i]}EN6`, servercnt),
          );
        }

        if (this.ams) {
          ret.push(System.getMLFBEntry(`${pref[i]}HC7`, servercnt));
        }

        if (this.sscadaana) {
          ret.push(System.getMLFBEntry(`${pref[i]}EP2`, servercnt));
        }
        if (this.sscadakpi) {
          ret.push(System.getMLFBEntry(`${pref[i]}EP0`, servercnt));
        }

        if (this.sscadakpiext > 0) {
          ret.push(System.getMLFBEntry(`${pref[i]}EP1`, this.sscadakpiext * servercnt));
        }
        if (this.secure) {
          ret.push(System.getMLFBEntry(`${pref[i]}EG0`, servercnt));
        }

        const videosrc = System.getStaffel(
          parseInt(this.videosrc),
          servercnt,
          [1, 10, 25, 50, 100, 250, 500],
          !this.redundant
            ? [
                `${pref[i]}ER0`,
                `${pref[i]}ER1`,
                `${pref[i]}ER2`,
                `${pref[i]}ER3`,
                `${pref[i]}ER4`,
                `${pref[i]}ER5`,
                `${pref[i]}ER6`,
              ]
            : [
                `${pref[i]}ES0`,
                `${pref[i]}ES1`,
                `${pref[i]}ES2`,
                `${pref[i]}ES3`,
                `${pref[i]}ES4`,
                `${pref[i]}ES5`,
                `${pref[i]}ES6`,
              ],
        );

        if (this.videoworkstation) {
          ret.push(System.getMLFBEntry(`${pref[i]}EQ4`, servercnt));
        }

        if (this.videobase === true) {
          // video base
          ret.push(
            System.getMLFBEntry(!this.redundant ? `${pref[i]}EQ1` : `${pref[i]}EU0`, servercnt),
          );
        }
        if (this.videolight === true) {
          // video light
          ret.push(System.getMLFBEntry(`${pref[i]}EQ0`, servercnt));
        }

        if (videosrc.length && videosrc.length > 0) {
          videosrc.map(vsrc => ret.push(System.getMLFBEntry(vsrc.mlfb, vsrc.anz)));
        }
        if (this.videodisp > 0) {
          ret.push(
            System.getMLFBEntry(
              !this.redundant ? `${pref[i]}EQ2` : `${pref[i]}EU1`,
              this.videodisp * servercnt,
            ),
          );
        }
        if (this.dist && this.videodisplaydist) {
          ret.push(System.getMLFBEntry(`${pref[i]}EQ3`, servercnt));
        }
      } else if (this.type === 'ODB') {
        if (this.dblic === 'asfu') {
          if (pricetype[i] === 'lic' || pricetype[i] === 'upg') {
            ret.push(System.getMLFBEntry('6AV6355-4AA00-0AA0', servercnt));
          } else if (pricetype[i] === 'sus' || pricetype[i] === 'sms') {
            if (i === 0) {
              ret.push(System.getMLFBEntry('6AV6355-4AA00-0AA1', servercnt));
            }
          }
        } else if (pricetype[i] === 'lic' || pricetype[i] === 'upg') {
          ret.push(System.getMLFBEntry('6AV6355-4AA00-0AA2', servercnt));
        } else if (pricetype[i] === 'sus' || pricetype[i] === 'sms') {
          if (i === 0) {
            ret.push(System.getMLFBEntry('6AV6355-4AA00-0AA3', servercnt));
          }
        }
      } else if (this.type === 'IDB') {
        // ret.push(System.getMLFBEntry('6AV6355-4AAA0',servercnt));
      } else if (this.type === 'RP') {
        ret.push(System.getMLFBEntry(`${pref[i]}CH2`, this.remotepara || 1));
      }
    }

    //special handling for dongle in nolic
    //display dongle if license is "lic" in any case
    //display dongle if license is "nolic" and sus === nosus, see #205
    if (license === 'lic' || (license === 'nolic' && sus === 'nosus')) {
      if (this.dongle) {
        ret.push(System.getMLFBEntry('6AV6355-4BA00-0AB0', servercnt));
      }
      if (this.dongleMicro) {
        ret.push(System.getMLFBEntry('6AV6355-4BA00-0AB1', servercnt));
      }
    }
    return ret;
  }

  rundAufNaechst(zahl, target) {
    return Math.ceil(zahl / target) * target;
  }

  toJSON() {
    const s = {};
    Object.getOwnPropertyNames(this).map(k => {
      if (k !== 'bus') {
        s[k] = this[k];
      }
    });
    return JSON.stringify(s);
  }
}
