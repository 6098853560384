import { System } from '../System';
import { Bus } from '../Bus';
import { html } from 'lit-element';
import { downloadZip } from 'client-zip';

export function export_csv() {
  const s = System.getAllSystems();
  const lines = [];
  let sus;
  let license;
  if ('license' in window.app.architecture) {
    sus = window.app.architecture.license.sus;
    license = window.app.architecture.license.license;
  } else {
    sus = 'sus';
    license = 'lic';
  }
  s.map(sys => {
    for (let i = 0; i <= sys.num - 1; i++) {
      sys.systemToMLFB(license, sus).map(li => {
        let parentSysName = '';
        if (sys.bus.parentId) {
          const parentBus = Bus.getBusById(sys.bus.parentId);
          if (parentBus && parentBus.partners && parentBus.partners.length) {
            parentSysName = `${parentBus.partners[0].name}(1)`;
          }
        }
        // const mlfbQtyGrandTotal = li.cnt * (sys.sysanz || 1);

        const srvn = i + 1;
        lines.push({
          sysName: `${sys.name}(${srvn})`,
          description: li.data[window.app.lang === 'de' ? 3 : 4],
          mlfb: li.mlfb,
          mlfbQtyGrandTotal: li.cnt * sys.bus.num || 1,
          mlfbQtyInInstance: li.cnt,
          instanceQtyGrandTotal: sys.bus.num || 1,
          groupTotal: sys.bus.num || 1,
          parentSysName: parentSysName,
        });
      });
    }
  });
  const csvContent = `data:text/csv;,${lines
    .map(
      l =>
        `${l.sysName};${l.description};${l.mlfb};${l.mlfbQtyGrandTotal};${l.mlfbQtyInInstance};${l.groupTotal};${l.parentSysName};${l.instanceQtyGrandTotal}`,
    )
    .join('\n')}`;
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  const nm = `${
    window.app.architecture.name ? toFileName(window.app.architecture.name) : 'export'
  }.csv`;
  link.setAttribute('download', nm);
  document.body.appendChild(link); // Required for FF
  link.click();
}

export function export_html(license, sus) {
  const getRenderString = data => {
    const { strings, values } = data;
    const value_list = [...values, '']; // + last empty part
    let output = '';
    for (let i = 0; i < strings.length; i++) {
      let v = value_list[i];
      if (v._$litType$ !== undefined) {
        v = template_as_string(v); // embedded Template
      } else if (v instanceof Array) {
        // array of strings or templates.
        let new_v = '';
        for (const inner_v of [...v]) {
          new_v += getRenderString(inner_v);
        }
        v = new_v;
      }
      output += strings[i] + v;
    }
    return output;
  };

  let renderString =
    '<style>* {font-family:arial}' +
    'table caption {font-weight:bold;font-size:150%;}' +
    'table,td { border:1px solid black;border-collapse:collapse;}</style>';
  System.getAllSystems().forEach(entry => {
    let renderResult = showSystem(entry);
    renderString = renderString + getRenderString(renderResult);
  });

  renderString =
    '<html><head>\n' +
    '  <meta charset="UTF-8">\n' +
    '</head><body>' +
    renderString +
    '</body></html>';
  //download directly
  const encodedUri = encodeURI(`data:text/html;,` + renderString);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  const nm = `${
    window.app.architecture.name ? toFileName(window.app.architecture.name) : 'export'
  }.html`;
  link.setAttribute('download', nm);
  document.body.appendChild(link); // Required for FF
  link.click();
}
function escapeXml(unsafe) {
  return unsafe.replace(/[<>&'"]/g, function (c) {
    switch (c) {
      case '<': return '&lt;';
      case '>': return '&gt;';
      case '&': return '&amp;';
      case '\'': return '&apos;';
      case '"': return '&quot;';
    }
  });
}

export async function export_xml() {
  let sus;
  let license;
  if ('license' in window.app.architecture) {
    sus = window.app.architecture.license.sus;
    license = window.app.architecture.license.license;
  } else {
    sus = 'sus';
    license = 'lic';
  }
  const s = System.getAllSystems();
  const lines = { license: [], soft_service: [] };
  s.map(sys => {
    sys.systemToMLFB(license, sus).map(li => {
      let parentSysName = '';
      if (sys.bus.parentId) {
        const parentBus = Bus.getBusById(sys.bus.parentId);
        if (parentBus && parentBus.partners && parentBus.partners.length) {
          parentSysName = `${parentBus.partners[0].name}(1)`;
        }
      }
      // const mlfbQtyGrandTotal = li.cnt * (sys.sysanz || 1);

      let target;
      switch (li.data[2]) {
        case 'POS':
        case 'SUS':
        case 'SMS':
          target = 'soft_service';
          break;
        default:
          target = 'license';
      }

      const description = li.data[window.app.lang === 'de' ? 3 : 4];
      let note = sys.name + ' ' + description;
      if (li.mlfb in sys.notes) {
        note = sys.notes[li.mlfb].note;
      }
      lines[target].push([
        escapeXml(sys.name),
        escapeXml(description),
        li.mlfb,
        1,
        'Stück',
        li.cnt * (sys.sysanz || 1),
        escapeXml(note),
      ]);
    });
  });
  let i = 0;

  const files = [];
  ['license', 'soft_service'].forEach(type => {
    if (lines[type].length === 0) {
      return;
    }

    const xmlContent = `${
      '<Cart_DT xmlns="http://siemens.com/ad/geb/cartmanagement/1.2" xmlns:i="http://www.w3.org/2001/XMLSchema-instance"><CreatorGID/><ID></ID><LastChangedGID/>' +
      '<SourceSystem></SourceSystem><Header><Language>' +
      '' + // window.app.lang
      '</Language><CustomerPONumberDublicated>false</CustomerPONumberDublicated><Notes><ExtendedProperties i:nil="true" xmlns:a="http://siemens.com/ad/geb/communication/1.0"/></Notes>' +
      '<Addresses><ExtendedProperties i:nil="true" xmlns:a="http://siemens.com/ad/geb/communication/1.0"/></Addresses><Pricing><CurrencyCode></CurrencyCode>' +
      '<Totals><CartTotal xmlns:a="http://siemens.com/ad/geb/pricing/1.2"><a:Value>0</a:Value><a:CurrencyCode></a:CurrencyCode><a:ShowPrice>true</a:ShowPrice></CartTotal>' +
      '<FreightFactorTotal xmlns:a="http://siemens.com/ad/geb/pricing/1.2"><a:Value>0</a:Value><a:CurrencyCode></a:CurrencyCode><a:ShowPrice>false</a:ShowPrice>' +
      '<a:ExtendedProperties xmlns:b="http://siemens.com/ad/geb/communication/1.0"></a:ExtendedProperties></FreightFactorTotal>' +
      '<MetalSurchargeTotals xmlns:a="http://siemens.com/ad/geb/pricing/1.2"/><ExtendedProperties i:nil="true" xmlns:a="http://siemens.com/ad/geb/communication/1.0"/></Totals>' +
      '<Surcharges xmlns:a="http://siemens.com/ad/geb/pricing/1.2"/><ExtendedProperties i:nil="true" xmlns:a="http://siemens.com/ad/geb/communication/1.0"/></Pricing>' +
      '<CompleteDelivery>false</CompleteDelivery><RequestedDeliveryDate>' +
      '0001-01-01T00:00:00' + // (new Date()).toISOString()
      '</RequestedDeliveryDate><ProjectNumber/>' +
      '' + // ((window.app&&window.app.architecture&&window.app.architecture.name)?window.app.architecture.name:'')
      // + '</ProjectNumber>'
      '<OrderType>220</OrderType><Shipping><DispatchMethod/></Shipping><IsExpressDelivery>false</IsExpressDelivery></Header><Items>'
    }
${lines[type]
  .map(l => getLineItem(l, ++i))
  .join(
    '\n',
  )}</Items><LastValidationTime>0001-01-01T00:00:00</LastValidationTime><Errors/><Status>Active</Status><LastChanged>0001-01-01T00:00:00</LastChanged></Cart_DT>`;

    files.push({
      name: 'export_' + type + '.xml',
      lastModified: new Date(),
      input: xmlContent,
    });
  });
  downloadZip(files)
    .blob()
    .then(res => {
      // make and click a temporary link to download the Blob
      const link = document.createElement('a');
      link.href = URL.createObjectURL(res);
      link.download = 'export.zip';
      link.click();
    });
}

function getLineItem(l, i) {
  return `<LineItem><ID></ID><LineItemNo>${i}</LineItemNo><MLFB>${l[2]}</MLFB><Quantity>${l[5]}</Quantity><QuantityUnit/><CustomerArticleNo>${l[0]}</CustomerArticleNo><Notes><FTEXT>${l[6]}</FTEXT></Notes><Errors><Error><ErrorCode></ErrorCode></Error></Errors><ConfiguratorID>0</ConfiguratorID><ComparisonRequired>false</ComparisonRequired></LineItem>`;
}

export function save() {
  const doc = JSON.stringify(window.app.architecture);
  const content = `data:text/json;,${doc}`;
  const encodedUri = encodeURI(content);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  const nm = `${
    window.app.architecture.name ? this.toFileName(window.app.architecture.name) : 'oa-config'
  }.json`;
  link.setAttribute('download', nm);
  document.body.appendChild(link); // Required for FF
  link.click();
}

function toFileName(nm) {
  if (!nm) {
    return null;
  }
  let nam = `${nm}`;
  nam = nam.toLowerCase();
  nam = nam.replace(/\s/g, '_');
  return nam;
}

function showSystem(sys) {
  let sus;
  let license;
  if ('license' in window.app.architecture) {
    sus = window.app.architecture.license.sus;
    license = window.app.architecture.license.license;
  } else {
    sus = 'sus';
    license = 'lic';
  }
  const lines = sys.systemToMLFB(license, sus);
  return html`
    <table class="blueTable">
      <caption>
        ${sys.name || ''}
      </caption>
      <col style="width:1%;" />
      <col style="width:10%;" />
      <col style="width:10%;" />
      <col style="width:30%;" />
      <col style="width:20%;" />
      <thead>
        <th></th>
        <th>${lt('mlfb')}</th>
        <th>${lt('short')}</th>
        <th>${lt('desc')}</th>
        <th></th>
      </thead>
      <tbody>
        ${lines.map(line => showLine(line, sys))}
      </tbody>
    </table>
  `;
}

function lt(nm) {
  const ltext = {
    de: {
      short: 'Kurzbezeichnung',
      desc: 'Beschreibung',
      mlfb: 'Artikelnummer',
    },
    en: {
      short: 'Short Description',
      desc: 'Description',
      mlfb: 'Product Code',
    },
  };
  return ltext[window.app.lang][nm];
}

function showLine(line, system) {
  const sysanz = system.sysanz || 1;

  // console.log(line);
  if (line.data) {
    if (line.mlfb) {
      let note = '';
      if (line.mlfb in system.notes) {
        note = system.notes[line.mlfb].note;
      }
      return html`
        <tr>
          <td>${line.cnt * sysanz}</td>
          <td>${line.mlfb}</td>
          <td>${line.data[window.app.lang === 'de' ? 3 : 4]}</td>
          <td>${line.data[window.app.lang === 'de' ? 6 : 7]}</td>
          <td>
            <div>
              <div style="white-space: break-spaces">${note}</div>

            </div>
          </td>

          </td>


        </tr>
      `;
    }

    return '';
  }

  console.log('mlfb not found : %d', line.mlfb);
}
