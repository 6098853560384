import { css, html, LitElement } from 'lit-element';
import { Import } from '../../import';

export class ArchList extends LitElement {
  static get styles() {
    return css`
      :host {
        display: block;
        padding: 5px;
        color: var(--page-main-text-color);
        align-items: center;
      }
      table {
        margin: 12px;
        border-collapse: collapse;
      }
      td,
      th {
        padding: 12px;
        border: 1px solid black;
      }
      th {
        background-color: #b9b9b9;
      }

      tr:hover {
        background-color: #aaa;
        cursor: pointer;
      }
      h2 {
        padding: 12px;
      }
    `;
  }

  static get properties() {
    return {
      lang: String,
    };
  }

  constructor() {
    super();

    this.archs = [
      {
        name: { en: 'Single Server', de: 'Einzelsystem' },
        description: {
          en: 'Single Server.',
          de: 'Einzelsystem.',
        },
        file: 'singleserver.json',
      },

      {
        name: { en: 'Dist', de: 'Dist' },
        description: {
          en: 'Distributed WinCC OA system without hierarchy.',
          de: 'Verteiltes WinCC OA System ohne Hierarchie.',
        },
        file: 'Dist-Flat.json',
      },
      {
        name: { en: 'Dist Hierarchy', de: 'Dist Hierarchy' },
        description: {
          en: 'Distributed WinCC OA system with hierarchical architecutre.',
          de: 'Verteiltes WinCC OA System mit hierarchischem Aufbau.',
        },
        file: 'Dist-Hierarchy.json',
      },
      {
        name: { en: 'DRS (RDB)', de: 'DRS (RDB)' },
        description: {
          en: 'Disaster Recovery System without hierarchy.',
          de: 'Disaster Recover System ohne Hierarchie.',
        },
        file: 'DRS-Flat.json',
      },
      {
        name: { en: 'DRS Hierarchy (RDB)', de: 'DRS Hierarchy (RDB)' },
        description: {
          en: 'Disaster Recovery System with hierarchical architecture. Multiple DRS systems are connected.',
          de: 'Disaster Recovery System mit hierarchischem Aufbau. Mehrere DRS Systeme sind angeschlossen.',
        },
        file: 'DRS-Hierarchy.json',
      },
      {
        name: { en: 'DRS (NGA)', de: 'DRS (NGA)' },
        description: {
          en: 'Disaster Recovery System without hierarchy.',
          de: 'Disaster Recover System ohne Hierarchie.',
        },
        file: 'DRS-NGA.json',
      },
      {
        name: { en: 'DRS Hierarchy (NGA)', de: 'DRS Hierarchy (NGA)' },
        description: {
          en: 'Disaster Recovery System with hierarchical architecture. Multiple DRS systems are connected.',
          de: 'Disaster Recovery System mit hierarchischem Aufbau. Mehrere DRS Systeme sind angeschlossen.',
        },
        file: 'DRS-Hierarchy-NGA.json',
      },
      {
        name: { en: 'APM (Playback)', de: 'APM (Playback)' },
        description: {
          en: 'Advanced Playback Manager configuration for playback only. For playback mode APM system and mirror system do not required additional PowerTags.',
          de: 'Advanced Playback Manager Konfiguration nur für die Wiedergabe. Für den Wiedergabemodus benötigen weder das APM-System noch das Spiegelsystem zusätzlichen PowerTags.',
        },
        file: 'APM-NoSim.json',
      },
      {
        name: { en: 'APM (with Sim)', de: 'APM (with Sim)' },
        description: {
          en: 'Advanced Playback Manager configuration for playback and simulation. For playback and simulation APM system and mirror system do required additional PowerTags.',
          de: 'Advanced Playback Manager-Konfiguration für Wiedergabe und Simulation. Für die Wiedergabe und Simulation benötigen sowohl das APM-System als auch das Spiegelsystem eine PowerTag Lizenz.',
        },
        file: 'APM-withSim.json',
      },
    ];
    this.ref = null;

    this.name = '';
    this.arch = null;
  }

  lt(cd) {
    const tx = {
      de: {
        configuration_has_been_loaded: 'Konfiguration wurde erfolgreich geladen',
        name: 'Name',
        description: 'Beschreibung',
        cp: 'Architektur auswählen',
        confirmOverride:
          'ACHTUNG! Dadurch wird die bestehende Konfiguration überschrieben. Möchten Sie fortfahren?',
      },
      en: {
        configuration_has_been_loaded: 'Configuration has been loaded',
        name: 'Name',
        description: 'Description',
        cp: 'Choose the architecture',
        confirmOverride:
          'CAUTION! This will override the existing Configuration. Do you want to proceed?',
      },
    };

    return tx[window.app.lang][cd];
  }

  render() {
    return html`
      
      <h2>${this.lt('cp')}</h2>
      <table>
          <tr>
              <th>${this.lt('name')}</th>
              <th>${this.lt('description')}</th>
          </tr>
          ${this.archs.map((item, idx) => this._fillItem(item))}</div>
    `;
  }

  _fillItem(item) {
    return html` <tr id="${item.file}" @click="${this._setArch}">
      <td>
        <strong>${item.name[this.lang]}</strong>
      </td>
      <td>${item.description[this.lang]}</td>
    </tr>`;
  }

  overrideArch(dialog) {
    window.app.architecture.initial = true;
    this.applyArch();
    this.ref.hide();
  }

  _setArch(e) {
    const filename = e.currentTarget.id;

    if (window.app.architecture.initial === false) {
      if (window.confirm(this.lt('confirmOverride'))) {
        this.applyArch(filename).then(() => {
          this.closeDialog(e);
        });
      }
    } else {
      window.app.architecture.initial = false;
      this.applyArch(filename).then(() => {
        this.closeDialog(e);
      });
    }
  }

  applyArch(filename) {
    return new Promise((resolve, reject) => {
      var xhttp = new XMLHttpRequest();
      xhttp.onreadystatechange = () => {
        if (xhttp.readyState === 4) {
          if (xhttp.status === 200) {
            const newArch = JSON.parse(xhttp.responseText);

            const importResult = Import(newArch);
            if (importResult === true) {
              alert(this.lt('configuration_has_been_loaded'));
            }

            window.app.requestUpdate();
            resolve(importResult);
          } else {
            reject(xhttp.statusText);
          }
        }
      };
      xhttp.open('GET', '/data/configurations/' + filename, true);
      xhttp.send();
    });
  }

  closeDialog(e) {
    this.dispatchEvent(new CustomEvent('close'));
  }
}
