import { html, css, LitElement } from 'lit-element';

export class F3nVmenu extends LitElement {
  static get styles() {
    return css`
      :host {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: var(--f3n-vmenu-background-color, #000);
        text-align: left;
      }

      *,
      :after,
      :before {
        box-sizing: border-box;
      }

      .f3n-menu-heading {
        --position: absolute;
        width: 100%;
        height: 40px;
        margin: auto;
      }

      .f3n-menu-header-item {
        margin-left: auto;
        margin-right: auto;
        margin-top: 18px;
        padding: 0;
        width: 94px;
        height: var(--f3n-vmenu-header-height, 36px);
      }

      .f3n-menu-heading,
      .f3n-menu-link {
        display: block;
        text-decoration: none;
        white-space: nowrap;
        margin: auto;
      }

      .f3n-menu-link {
        position: fixed;
        display: block;
        top: 0;
        left: 0;
        background: var(--f3n-vmenu-background-color, #000);
        background: rgba(0, 0, 0, 0.7);
        font-size: 11px;
        z-index: 10;
        width: 4em;
        height: 4em;
        padding: 1em;
      }

      .f3n-menu-list {
        list-style: none;
        margin: 0;
        padding: 0;
      }

      .icon {
        height: 32px;
        width: 32px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
        margin: auto;
        position: absolute;
        left: 0;
        right: 0;
        top: 15px;
      }

      .f3n-menu-item {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        display: block;
        position: relative;
        height: 100px;
        color: var(--f3n-vmenu-color, #fff);
        text-align: center;
        cursor: pointer;
        margin: 1px 0;
      }

      .f3n-menu-item:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 8px;
        bottom: 0;
      }

      .f3n-menu-item.selected,
      .f3n-menu-item.selected:hover {
        background: var(--f3n-vmenu-color, #fff);
        color: var(--f3n-vmenu-background-color, #000);
      }
      .f3n-menu-item.selected:before,
      .f3n-menu-item.selected:hover:before {
        background: var(--f3n-vmenu-select-color, #ff8000);
      }

      .f3n-menu-item:hover {
        background: #424b68;
      }

      .f3n-menu-item:hover:before {
        background: #8495bf;
      }

      .f3n-menu-item-inner {
        display: block;
        border-bottom: 1px solid grey;

        position: relative;
        width: 100%;
        height: 100%;
      }

      .f3n-menu-item.start .f3n-menu-item-inner:before {
        content: '';
        position: absolute;
        top: -1px;
        left: 8px;
        right: 0;
        height: 1px;
        background: #60687e;
      }

      .f3n-menu-item.selected .icon.icon-start-w32 {
        background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20width%3D%2732%27%20height%3D%2732%27%20viewBox%3D%270%200%2032%2032%27%3E%3Cpath%20fill-rule%3D%27evenodd%27%20clip-rule%3D%27evenodd%27%20fill%3D%27%233A435E%27%20d%3D%27M1%2031h4V1H1v30zm22-19v7l-8-7v7l-8-7v19h24V19l-8-7z%27%2F%3E%3C%2Fsvg%3E');
      }

      .f3n-menu-text-wrapper {
        display: table;
        position: absolute;
        top: 46px;
        width: 100%;
        height: 54px;
        padding-top: 10px;
      }

      .text-wrapper .text {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        height: 100%;
        width: 100%;
        padding: 0 15px;
      }

      #layout,
      #menu,
      .f3n-menu-link {
        -webkit-transition: all 0.2s ease-out;
        -moz-transition: all 0.2s ease-out;
        -ms-transition: all 0.2s ease-out;
        -o-transition: all 0.2s ease-out;
        transition: all 0.2s ease-out;
      }

      .f3n-menu-link:hover,
      .f3n-menu-link:focus {
        background: #000;
      }

      .f3n-menu-link span {
        position: relative;
        display: block;
      }

      .f3n-menu-link span,
      .f3n-menu-link span:before,
      .f3n-menu-link span:after {
        background-color: #fff;
        width: 100%;
        height: 0.2em;
      }

      .f3n-menu-link span:before,
      .f3n-menu-link span:after {
        position: absolute;
        margin-top: -0.6em;
        content: ' ';
      }

      .f3n-menu-link span:after {
        margin-top: 0.6em;
      }

      .content {
        margin: 0 auto;
        padding: 0 2em;
        max-width: 800px;
        margin-bottom: 50px;
        line-height: 1.6em;
      }

      .header {
        margin: 0;
        color: #333;
        text-align: center;
        padding: 2.5em 2em 0;
        border-bottom: 1px solid #eee;
      }

      #menu {
        margin-left: var(--f3n-vmenu-width, -120px); /* "#menu" width */
        width: var(--f3n-vmenu-width, 120px);
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 1000; /* so the menu or its navicon stays above all content */
        background: #3a435e;
        overflow-y: auto;
      }

      #menu a {
        color: #999;
        border: none;
        padding: 0.6em 0 0.6em 0.6em;
      }
      #menu .f3n-menu,
      #menu .f3n-menu ul {
        border: none;
        background: transparent;
      }

      #menu .f3n-menu ul,
      #menu .f3n-menu .menu-item-divided {
        border-top: 1px solid #ccc;
      }

      #menu .f3n-menu-heading {
        font-size: 110%;
        color: #fff;
        margin: 0;
      }

      @media (min-width: 48em) {
        .header,
        .content {
          padding-left: 2em;
          padding-right: 2em;
        }

        #--layout {
          padding-left: var(--f3n-vmenu-width, 120px); /* left col width "#menu" */
          left: 0;
        }
        #menu {
          left: var(--f3n-vmenu-width, 120px);
        }

        .f3n-menu-link {
          position: fixed;
          left: var(--f3n-vmenu-width, 120px);
          display: none;
        }

        #--layout.active .f3n-menu-link {
          left: var(--f3n-vmenu-width, 120px);
        }
      }

      @media (max-width: 48em) {
        #menu.active {
          position: relative;
          left: var(--f3n-vmenu-width, 120px);
        }
      }
    `;
  }

  static get properties() {
    return {
      menu: { type: Object },
    };
  }

  constructor() {
    super();
    this.menu = [];
  }

  firstUpdated() {
    this.ele_layout = this.shadowRoot.getElementById('layout');
    this.ele_menu = this.shadowRoot.getElementById('menu');
    this.ele_menuLink = this.shadowRoot.getElementById('menuLink');
  }

  toggleClass(element, className) {
    let classes = element.className.split(/\s+/),
      length = classes.length,
      i = 0;

    for (; i < length; i++) {
      if (classes[i] === className) {
        classes.splice(i, 1);
        break;
      }
    }
    // The className is not found
    if (length === classes.length) {
      classes.push(className);
    }

    element.className = classes.join(' ');
  }

  toggleAll(e) {
    var active = 'active';

    e.preventDefault();
    //this.toggleClass(this.ele_layout, active);
    this.toggleClass(this.ele_menu, active);
    this.toggleClass(this.ele_menuLink, active);
  }

  getMenuItem(entry, idx) {
    let fgcolor = getComputedStyle(this).getPropertyValue('--f3n-vmenu-color', '#fff');
    let bgcolor = getComputedStyle(this).getPropertyValue('--f3n-vmenu-background-color', '#000');
    let size = getComputedStyle(this).getPropertyValue('--f3n-vmenu-icon-size', '42');

    let fillco = entry.selected ? '#3A435E' : 'white';

    let cl = ['f3n-menu-item'];
    if (entry.selected) cl.push('selected');
    if (entry.divided) cl.push('menu-item-divided');

    return html`
      <li>
        <div class="${cl.join(' ')}" @click="${this.menuClick.bind({ menu: this, entry: entry })}">
          <span class="f3n-menu-item-inner">
            <span class="icon">
              <sie-icon icon="${entry.icon}" fill="${fillco}" size="${size}"></sie-icon>
            </span>
            <span class="f3n-menu-text-wrapper"> ${entry.label} </span>
          </span>
        </div>
      </li>
    `;
  }

  menuClick(e) {
    let _this = this.menu;
    let entry = this.entry;
    let myEvent = new CustomEvent('menu-selected', {
      detail: { page: entry.page },
      bubbles: true,
      composed: true,
    });
    _this.dispatchEvent(myEvent);
    _this.menu.map(it => {
      it.selected = it.page === entry.page;
    });
    _this.requestUpdate();
    _this.toggleAll(e);
  }

  render() {
    return html`
      <!-- Menu toggle -->
      <div href="#menu" id="menuLink" class="f3n-menu-link" @click="${this.toggleAll}">
        <!-- Hamburger icon -->
        <sie-icon icon="burger" fill="#fff" size="24"></sie-icon>
      </div>

      <div id="menu">
        <div class="f3n-menu">
          <div class="f3n-menu-header-item">
            <sie-icon icon="siemens-logo" fill="#fff" size="32"></sie-icon>
          </div>

          <ul class="f3n-menu-list">
            ${this.menu ? this.menu.map((entry, idx) => this.getMenuItem(entry, idx)) : ''}
          </ul>
        </div>
      </div>
    `;
  }
}
