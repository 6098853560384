import { css, html, LitElement } from 'lit-element';

import { System } from '../../../System.js';
import { showDialog } from 'weightless';
import { NotesEdit } from './NotesEdit';
import {export_xml} from '../../export.js';

export class PageOne extends LitElement {
  constructor() {
    super();
    this.title = '';
    this.arch = null;
    this.license = window.app.architecture.license['license'] || 'lic';
    this.sus = window.app.architecture.license['sus'] || 'sus';
    this.curSystem = undefined;
  }

  static get styles() {
    return css`
      :host {
        --page-one-text-color: #000;
        --button-bg: #3a435e;
        display: block;
        padding: 25px;
        color: var(--page-one-text-color);
      }

      table.blueTable {
        border: 1px solid #1c6ea4;
        background-color: var(--f3n-vmenu-background-color);
        width: 100%;
        text-align: left;
        border-collapse: collapse;
      }

      table.blueTable caption {
        background-color: var(--f3n-vmenu-background-color);
        width: 100%;
        text-align: left;
        border-collapse: collapse;
      }

      table.blueTable td,
      table.blueTable th {
        border: 1px solid #aaaaaa;
        padding: 8px;
        line-height: 20px;
        vertical-align: top;
      }

      table.blueTable tr:nth-child(even) {
        background: rgb(223, 227, 238);
      }

      table.blueTable thead {
        background-color: #3a435e;
        --background: -moz-linear-gradient(top, #3a435e 0%, #327cad 66%, #1c6ea4 100%);
        --background: -webkit-linear-gradient(top, #3a435e 0%, #327cad 66%, #1c6ea4 100%);
        --background: linear-gradient(to bottom, #3a435e 0%, #327cad 66%, #1c6ea4 100%);
        border-bottom: 2px solid #444444;
      }

      table.blueTable thead th {
        font-size: 16px;
        font-weight: bold;
        color: #ffffff;
        border-left: 2px solid #d0e4f5;
      }

      table.blueTable thead th:first-child {
        border-left: none;
      }

      table.blueTable tfoot {
        font-size: 14px;
        font-weight: bold;
        color: #ffffff;
        background: #d0e4f5;
        background: -moz-linear-gradient(top, #dcebf7 0%, #d4e6f6 66%, #d0e4f5 100%);
        background: -webkit-linear-gradient(top, #dcebf7 0%, #d4e6f6 66%, #d0e4f5 100%);
        background: linear-gradient(to bottom, #dcebf7 0%, #d4e6f6 66%, #d0e4f5 100%);
        border-top: 2px solid #444444;
      }

      table.blueTable tfoot td {
        font-size: 14px;
      }

      table.blueTable tfoot .links {
        text-align: right;
      }

      table.blueTable tfoot .links a {
        display: inline-block;
        background: #1c6ea4;
        color: #ffffff;
        padding: 2px 8px;
        border-radius: 5px;
      }

      wl-label {
        color: black;
      }

      caption {
        font-size: 16px;
      }

      td,
      th {
        font-size: 15px;
        line-height: 20px;

        padding: 8px;
      }
    `;
  }

  static get properties() {
    return {
      arch: { type: Object },
      license: { type: String },
      sus: { type: String },
      lang: { type: String },
    };
  }

  showCommentPopup(e) {
    const systemId = e.currentTarget.getAttribute('data-system-id');
    const system = System.getSystemById(systemId);
    if (!system) {
      throw new Error('System not found');
    }
    const mlfb = e.currentTarget.getAttribute('data-mlfb');
    this.curSystem = System.getSystemById(systemId);
    showDialog({
      fixed: true,
      backdrop: true,
      blockScrolling: true,
      container: document.body,
      size: 'small',
      duration: 200,
      template: NotesEdit(system, mlfb),
    }).then(dlg => {
      dlg.result.then(() => {
        this.requestUpdate();
      });
      // e.srcElement.parentElement.parentElement.requestUpdate();
    });
  }

  showLine(line, system) {
    const sysanz = system.sysanz || 1;

    // console.log(line);
    if (line.data) {
      if (line.mlfb) {
        let note = '';
        if (line.mlfb in system.notes) {
          note = system.notes[line.mlfb].note;
        }
        return html`
          <tr>
            <td>${line.cnt * sysanz}</td>
            <td>${line.mlfb}</td>
            <td>${line.data[window.app.lang === 'de' ? 3 : 4]}</td>
            <td>${line.data[window.app.lang === 'de' ? 6 : 7]}</td>
            <td>
              <div style="display:flex">
                <div style="flex:1;white-space: break-spaces">${note}</div>
                <div>
                  <wl-button fab data-system-id="${system.id}"
                             data-mlfb="${line.mlfb}"
                             style="color:white"
                             @click="${this.showCommentPopup}">
                    <wl-icon>insert_comment</wl-icon>

                  </wl-button>
                </div>
              </div>
            </td>

            </td>


          </tr>
        `;
      }

      return '';
    }

    console.log('mlfb not found : %d', line.mlfb);
  }

  lt(nm) {
    const ltext = {
      de: {
        disable_sus:
          'POS oder SUS oder SMS für optimalen Betrieb erforderlich.  "OK" wird die Software Services abwählen.',
        multiple_file_download:
          'Um den Bestellvorgang so einfach wie möglich zu gestalten, bitten wir sie Lizenz- sowie Software-Service in zwei Bestellungen in der Industry MALL aufzuteilen. Aus diesem Grund werden für die ausgewählte Konfiguration zwei Exportdateien erzeugt.',
        license: 'Lizenzen',
        maint: 'SUS / SMS',
        upg: 'Lizenzupgrade',
        sus: 'SUS - Software Update Service ',
        sms: 'SMS - Software Maintenance Service ',
        pos: 'POS - Patch Only Service',
        nolic: 'Keine Lizenzen',
        nosus: 'Kein SUS/SMS',
        short: 'Kurzbezeichnung',
        desc: 'Beschreibung',
        mlfb: 'Artikelnummer',
      },
      en: {
        disable_sus:
          'POS or SUS or SMS required for optimum operation.  "OK" will deselect the software services.',
        multiple_file_download:
          'To make the ordering process as simple as possible, we ask you to split the license and software service into two orders in Industry MALL. For this reason, two export files are created for the selected configuration.',
        license: 'Licenses',
        maint: 'SUS / SMS',
        upg: 'License Upgrade',
        sus: 'SUS - Software Update Service ',
        sms: 'SMS - Software Maintenance Service ',
        pos: 'POS - Patch Only Service',
        nolic: 'No Licenses',
        nosus: 'No SUS/SMS',
        short: 'Short Description',
        desc: 'Description',
        mlfb: 'Product Code',
      },
    };
    return ltext[window.app.lang][nm];
  }

  closeNotesEdit() {
    this.shadowRoot.querySelector('#dialog').hide();
  }

  showSystem(sys) {
    const lines = sys.systemToMLFB(this.license, this.sus);
    return html`
      <table class="blueTable">
        <caption>
          ${sys.name || ''}
        </caption>
        <col style="width:1%;" />
        <col style="width:10%;" />
        <col style="width:10%;" />
        <col style="width:30%;" />
        <col style="width:20%;" />
        <thead>
          <th></th>
          <th>${this.lt('mlfb')}</th>
          <th>${this.lt('short')}</th>
          <th>${this.lt('desc')}</th>
          <th></th>
        </thead>
        <tbody>
          ${lines.map(line => this.showLine(line, sys))}
        </tbody>
      </table>
    `;
  }

  assign(e) {
    const ele = e.currentTarget;
    // console.log('Assign ',ele.name);
    if (ele.name) {
      this.this[ele.name] = this.this.getFieldValue(ele);

      window.app.architecture.license[ele.name] = this.this[ele.name];
    }
    this.this.requestUpdate();
  }

  onSusChange(e) {
    const before = this.sus;

    if (confirm(this.lt('disable_sus'))) {
    } else {
      this.sus = before;
      e.target.checked = false;
      e.target.getRootNode().getElementById(before).checked = true;
      e.stopPropagation();
      return false;
    }

    this.sus = e.target.value;
    window.app.architecture.license['sus'] = 'nosus';

    this.requestUpdate();
  }

  getFieldValue(ele) {
    let res = null;
    if (ele.tagName === 'WL-CHECKBOX') {
      res = ele.checked;
    } else if (ele.tagName === 'WL-RADIO') {
      res = ele.value;
    } else if (ele.type === 'number') {
      res = ele.valueAsNumber;
    } else if (ele.type === 'text') {
      res = ele.value;
    } else if (ele.type === 'date') {
      res = ele.valueAsDate;
    } else if (ele.type === 'select-one') {
      res = ele.value;
    }
    return res;
  }

  render() {
    const env = { this: this, data: null };
    const that = this;
    const s = System.getAllSystems();
    this.title = window.app.architecture.name || '';
    return html`
      <h2>${this.title}</h2>
      <fieldset style="display:flex;padding:15px;">
        <legend>${this.lt('license')}</legend>
        <div style="display:inline;  flex:1;margin:3px;">
          <wl-label>
            <wl-radio
              name="license"
              value="lic"
              @change="${that.assign.bind(env)}"
              .checked="${this.license === 'lic' || false}"
            ></wl-radio>
            ${this.lt('license')}
          </wl-label>
        </div>
        <div style="display:inline;  flex:1;margin:3px;">
          <wl-label>
            <wl-radio
              name="license"
              value="upg"
              @change="${that.assign.bind(env)}"
              .checked="${this.license === 'upg' || false}"
            ></wl-radio>
            ${this.lt('upg')}
          </wl-label>
        </div>
        <div style="display:inline; flex:1;margin:3px;">
          <wl-label>
            <wl-radio
              name="license"
              value="nolic"
              @change="${that.assign.bind(env)}"
              .checked="${this.license === 'nolic' || false}"
            ></wl-radio>
            ${this.lt('nolic')}
          </wl-label>
        </div>
      </fieldset>
      <fieldset style="display:flex;padding:15px;">
        <legend>${this.lt('maint')}</legend>

        <div style="display:inline;  flex:1;margin:3px;">
          <wl-label>
            <wl-radio
              name="sus"
              value="sus"
              id="sus"
              @change="${that.assign.bind(env)}"
              .checked="${this.sus === 'sus' || false}"
            ></wl-radio>
            ${this.lt('sus')}
          </wl-label>
        </div>
        <div style="display:inline;  flex:1;margin:3px;">
          <wl-label>
            <wl-radio
              name="sus"
              value="sms"
              id="sms"
              @change="${that.assign.bind(env)}"
              .checked="${this.sus === 'sms' || false}"
            ></wl-radio>
            ${this.lt('sms')}
          </wl-label>
        </div>
        <div style="display:inline;  flex:1;margin:3px;">
          <wl-label>
            <wl-radio
              name="sus"
              value="pos"
              id="pos"
              @change="${that.assign.bind(env)}"
              .checked="${this.sus === 'pos' || false}"
            ></wl-radio>
            ${this.lt('pos')}
          </wl-label>
        </div>
        <div style="display:inline;  flex:1;margin:3px;">
          <wl-label>
            <wl-radio
              name="sus"
              value="nosus"
              id="nosus"
              @change="${that.onSusChange}"
              .checked="${this.sus === 'nosus' || false}"
            ></wl-radio>
            ${this.lt('nosus')}
          </wl-label>
        </div>
      </fieldset>
      <div>${s.map(sys => this.showSystem(sys))}</div>
      <div style="position:fixed; bottom:5px; right:5px;">
        <wl-button @click="${this.onExportXmlClick.bind(this)}">Export MALL</wl-button>
      </div>
    `;
  }

  getLineItem(l, i) {
    return `<LineItem><ID></ID><LineItemNo>${i}</LineItemNo><MLFB>${l[2]}</MLFB><Quantity>${l[5]}</Quantity><QuantityUnit/><CustomerArticleNo>${l[0]}</CustomerArticleNo><Notes><FTEXT>${l[6]}</FTEXT></Notes><Errors><Error><ErrorCode></ErrorCode></Error></Errors><ConfiguratorID>0</ConfiguratorID><ComparisonRequired>false</ComparisonRequired></LineItem>`;
  }

  async onExportXmlClick() {
    return export_xml();
  }

  toFileName(nm) {
    if (!nm) {
      return null;
    }
    let nam = `${nm}`;
    nam = nam.toLowerCase();
    nam = nam.replace(/\s/g, '_');
    return nam;
  }
}
