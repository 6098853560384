export class Bus {
  constructor() {
    this.partners = [];
    this.busses = [];
    this.parentId = undefined;
    this.id = cuid();
  }

  /**
   *
   * @param id
   * @returns Bus
   */
  static getBusById(id) {
    let ret = null;
    const rf = (bus, busId) => {
      if (bus.id === busId) {
        ret = bus;
        return bus;
      }

      if (bus.busses && !ret) {
        bus.busses.forEach(b => {
          const s = rf(b, busId);
          if (s) {
            ret = s;
            return s;
          }
        });
      }
      return ret;
    };

    window.app.architecture.busses.forEach(b => {
      const s = rf(b, id);
      if (s) {
        ret = s;
        return s;
      }
    });
    return ret;
  }

  getRootBus() {
    if (this.parentId === undefined) {
      return this;
    }
    const parentBus = Bus.getBusById(this.parentId);
    return parentBus.getRootBus();
  }

  getSurroundingOaSystems(includeIpc = false) {
    let returnValue = [];

    // check partners on same bus
    if (this.getAmountOASystems(includeIpc)) {
      returnValue = [...returnValue, ...this.getSystemsByType('OA', includeIpc)];
    }

    // check parent bus
    if (this.parentId) {
      const parentBus = Bus.getBusById(this.parentId);
      if (parentBus.getAmountOASystems(includeIpc)) {
        returnValue = [...returnValue, ...parentBus.getSystemsByType('OA', includeIpc)];
      }
    }

    // check children
    this.busses.forEach(childBus => {
      if (childBus.getAmountOASystems(includeIpc)) {
        returnValue = [...returnValue, ...childBus.getSystemsByType('OA', includeIpc)];
      }
    });
    return returnValue;
  }

  removePartner(id) {
    for (let i = 0; i < this.partners.length; i += 1) {
      if (this.partners[i].id === id) {
        this.partners.splice(i, 1);
      }
    }
    return false;
  }

  addPartner(system) {
    // inject bus into system
    system.setBus(this);
    this.partners.push(system);
    return true;
  }

  /**
   * returns the amount of OA Systems on the Bus
   */
  getAmountOASystems(includeIpc = false) {
    if (includeIpc === true) {
      return this.partners.filter(p =>
        ['OA', 'OA_IPC128', 'OA_IPC256', 'OA_IPC512', 'OA_IPC2048', 'OA_IPC4096'].includes(p.type),
      ).length;
    } else {
      return this.partners.filter(p => p.type === 'OA').length;
    }
  }

  /**
   * @return System[]
   */
  getSystemsByType(type, includeIPC = false) {
    if (includeIPC === true) {
      return this.partners.filter(
        p =>
          p.type === type ||
          ['OA_IPC128', 'OA_IPC256', 'OA_IPC512', 'OA_IPC2048', 'OA_IPC4096'].includes(p.type),
      );
    } else {
      return this.partners.filter(p => p.type === type);
    }
  }

  removeBus(bus) {
    this.busses.forEach((busIterator, index) => {
      if (bus === busIterator) {
        this.busses.splice(index, 1);
        return true;
      }
    });
    return false;
  }

  addBus(newBus) {
    newBus.setParentId(this.id);
    this.busses.push(newBus);
    return true;
  }

  setParentId(parentId) {
    this.parentId = parentId;
  }

  incBusNum() {
    const n = this.num || 1;
    this.num = n + 1;
  }

  decBusNum() {
    const n = this.num || 1;
    this.num = n > 1 ? n - 1 : 0;
  }
}
