import { css, html, LitElement } from 'lit-element';

import { export_csv, export_html, export_xml, save } from '../../export';

export class PageExport extends LitElement {
  constructor() {
    super();
    this.title = '';
  }

  static get styles() {
    return css`


      .container {
        display:flex;
        gap:40px;
        flex-wrap:wrap;
        margin:60px;
      }
      .box {
        padding:20px;
        border:1px solid grey;
        height:530px;
        min-width:300px;
        flex:1;
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      .title {
        flex: 0 0 120px;
        font-weight:bold;
        text-align:center;
        font-size:2rem;
        margin:30px;
      }

      .list {
      }
      ul {
        list-style-type: none;
        padding:0
      }
      .description {
        flex: 0 0 130px;
      }

      .button {
    `;
  }

  static get properties() {
    return {
      lang: { type: String },
    };
  }

  lt(nm) {
    const ltext = {
      de: {
        mall_import: 'Mall Import',
        save_architecture: 'Architektur speichern',
        save_architecture_description:
          'Speichern Sie die entworfene Architektur zur späteren Wiederverwendung im WINCC OA Konfigurator. Bitte verwenden Sie dieses Format für die Kommunikation.',
        csv_title: 'CSV Format für internes Vertriebs Tool',
        html_title: 'Lesbares Format (HTML)',
        html_description:
          'Exportieren Sie die Stückliste in einem für den Menschen lesbaren, tabellarischen Format.',
        //csv_description : 'Spezielles CSV-Format nur zur Verwendung im Excel-Rechner für den Vertrieb. Nach dem Export nicht mehr verändern. Bitte verwenden Sie bei der Kommunikation immer "Architektur speichern"!',
        csv_description:
          'Spezielles CSV-Format nur zur Verwendung im Excel-Kalkulator für den Vertrieb. Nach dem Export nicht mehr ändern. Beschränkung der Länge des "Systemnamens" auf 27 Zeichen. Bitte verwenden Sie bei der Kommunikation immer "Architektur speichern"!',
        reimport: 'Reimport in den Konfigurator',
        human_readable: 'Für Menschen lesbar',
        not_editable_csv: 'Unveränderbares CSV Format',
        export_xml: 'Export XML für Mall',
        export_csv: 'Export CSV für Kalkulator',
        export_html: 'Export HTML',
        industry_mall: 'Industry Mall',
        industry_mall_info:
          'Verwenden Sie das XML-File für den Import in den Siemens Mall Einkaufswagen.',
      },
      en: {
        mall_import: 'Mall Import',
        save_architecture: 'Save Architecture',
        save_architecture_description:
          'Save the designed architecture for later reuse in the WINCC OA configurator. Pleaes use this format for communication.',
        csv_title: 'Internal Sales Calculator (CSV)',
        html_title: 'Human readable table (HTML)',
        html_description: 'Export the bill of material in human-readable tabular format',
        csv_description:
          'Special CSV format for usage in sales excel calculator only. Do not modify after export. ' +
          'Restriction of length of "System Name" to 27 characters. ' +
          'When communicating please always use "Save Architecture"!',
        reimport: 'Reimport in Configurator',
        human_readable: 'Human Readable',
        not_editable_csv: 'Sales Calculator import',
        export_xml: 'Export XML for Mall',
        export_csv: 'Export CSV for Calculator',
        export_html: 'Export HTML',
        industry_mall: 'Industry Mall',
        industry_mall_info: 'Use the XML for importing into the Siemens Mall shopping cart.',
      },
    };
    return ltext[window.app.lang][nm];
  }

  render() {
    this.title = 'Export' + window.app.architecture.name || '';
    return html`
      <div class="container">
        <!-- xml -->
        <div class="box">
          <div class="title">${this.lt('industry_mall')}<br />(XML)</div>
          <div class="description">${this.lt('industry_mall_info')}</div>
          <div class="list">
            <ul>
              <li>
                <wl-icon>check</wl-icon>
                ${this.lt('mall_import')}
              </li>
              <li>
                <wl-icon>clear</wl-icon>
                ${this.lt('reimport')}
              </li>
              <li>
                <wl-icon>clear</wl-icon>
                ${this.lt('human_readable')}
              </li>
              <li>
                <wl-icon>clear</wl-icon>
                ${this.lt('not_editable_csv')}
              </li>
            </ul>
          </div>
          <div class="button">
            <wl-button @click="${() => export_xml()}">${this.lt('export_xml')}</wl-button>
          </div>
        </div>

        <!-- architecture -->
        <div class="box">
          <div class="title">${this.lt('save_architecture')}</div>
          <div class="description">${this.lt('save_architecture_description')}</div>
          <div class="list">
            <ul>
              <li>
                <wl-icon>clear</wl-icon>
                ${this.lt('mall_import')}
              </li>
              <li>
                <wl-icon>check</wl-icon>
                ${this.lt('reimport')}
              </li>
              <li>
                <wl-icon>clear</wl-icon>
                ${this.lt('human_readable')}
              </li>
              <li>
                <wl-icon>clear</wl-icon>
                ${this.lt('not_editable_csv')}
              </li>
            </ul>
          </div>
          <div class="button">
            <wl-button @click="${() => save()}">Export Architecture</wl-button>
          </div>
        </div>

        <!-- html -->
        <div class="box">
          <div class="title">${this.lt('html_title')}</div>
          <div class="description">${this.lt('html_description')}</div>
          <div class="list">
            <ul>
              <li>
                <wl-icon>clear</wl-icon>
                ${this.lt('mall_import')}
              </li>
              <li>
                <wl-icon>clear</wl-icon>
                ${this.lt('reimport')}
              </li>
              <li>
                <wl-icon>check</wl-icon>
                ${this.lt('human_readable')}
              </li>
              <li>
                <wl-icon>clear</wl-icon>
                ${this.lt('not_editable_csv')}
              </li>
            </ul>
          </div>
          <div class="button">
            <wl-button @click="${() => export_html()}">${this.lt('export_html')}</wl-button>
          </div>
        </div>

        <!-- csv -->
        <div class="box">
          <div class="title">${this.lt('csv_title')}</div>
          <div class="description">${this.lt('csv_description')}</div>
          <div class="list">
            <ul>
              <li>
                <wl-icon>clear</wl-icon>
                ${this.lt('mall_import')}
              </li>
              <li>
                <wl-icon>clear</wl-icon>
                ${this.lt('reimport')}
              </li>
              <li>
                <wl-icon>clear</wl-icon>
                ${this.lt('human_readable')}
              </li>
              <li>
                <wl-icon>check</wl-icon>
                ${this.lt('not_editable_csv')}
              </li>
            </ul>
          </div>
          <div class="button">
            <wl-button @click="${() => export_csv()}">${this.lt('export_csv')}</wl-button>
          </div>
        </div>
      </div>
    `;
  }
}
