import { html } from 'lit-element';

const ltext = {
  de: {
    comment: 'Kommentar',
  },
  en: {
    comment: 'Comment',
  },
};

function lt(nm) {
  return ltext[window.app.lang][nm];
}

let systemObj;
let _mlfb;

export function NotesEdit(system, mlfb) {
  systemObj = system;
  _mlfb = mlfb;
  if (!(mlfb in systemObj.notes)) {
    systemObj.notes[mlfb] = { note: '', date: new Date() };
  }
  //maxLength = 14 rows * 31 chars = 434 chars, see https://code.siemens.com/markus.uebeleis.ext/winccoa-configurator/-/issues/139
  return html`
    <div style="padding:20px;">
      <h3>${lt('comment')} (max. 434 chars)</h3>
      <wl-textarea
        style=" --input-border-style:0;--textarea-height: 350px;--textarea-max-height: 350px;--textarea-min-height: 350px;"
        data-mlfb="${mlfb}"
        maxLength="434"
        name="notesEditTextArea"
        @input="${onNoteUpdate}"
        value="${systemObj.notes[mlfb].note}"
      >
      </wl-textarea>
    </div>
  `;
}

function onNoteUpdate(e) {
  const ele = e.target;
  systemObj.notes[_mlfb].note = ele.value;
}
