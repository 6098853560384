import { css, html, LitElement } from 'lit-element';

export class PageStart extends LitElement {
  static get styles() {
    return css`
      .start-div {
        position: fixed;
        height: 100%;
        width: 100%;
      }

      .start-button {
        position: absolute;
        bottom: 150px;
        height: 50px;
        color: white;
        font-size: 16px;
        font-weight: 500;
        background: linear-gradient(to right, #50bebe, #0299ca);
        border: 0px;
        width: 200px;
        left: 400px;
      }
    `;
  }

  static get properties() {
    return {
      lang: { type: String },
    };
  }

  constructor() {
    super();
    this.title = 'Start';
    this.lang = window.app.lang;
  }

  lt(nm) {
    let ltext = {
      de: {
        //  license: 'Lizenzen',
      },
      en: {
        //  license: 'Licenses',
      },
    };
    return ltext[window.app.lang][nm];
  }

  navigateToMain() {
    this.dispatchEvent(new CustomEvent('nav-change', { detail: { navigateTo: 'main' } }));
  }

  render() {
    return html`
      <div
        style="background:url('/media/wincc_oa_konfigurator_v3_20_${this
          .lang}.png');background-size: cover;"
        class="start-div"
      ></div>
    `;
  }
}
