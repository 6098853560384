import { html } from 'lit-html';

let lang = window.app ? window.app.lang : navigator.language.substring(0, 2);
if (lang !== 'de' && lang !== 'en') {
  lang = 'en';
}

export const ViewportSizeWarning =
  lang === 'en'
    ? html`
        <div style="padding:30px;">
          <h2>Display not optimized</h2>
          <p>
            The application is not optimized for the screensize of your device. The layout may be
            incorrect. We recommend devices from 7".
          </p>
        </div>
      `
    : html`
        <div style="padding:30px;">
          <h2>Darstellung nicht optimiert</h2>
          <p>
            Die Applikation ist nicht für die Größe ihres Geräts optimiert. Es kann zu fehlerhafter
            Darstellung kommen. Wir empfehlen Geräte ab 7".
          </p>
        </div>
      `;
