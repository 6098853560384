import { html } from 'lit-element';

const ltext = {
  de: {
    successHeader: 'Datei wurde erfolgreich importiert',
    indivLicHeader: 'Individuelle oder abgekündigte Lizenzoption(en) erkannt',
    downloadReport: 'Report herunterladen',
  },
  en: {
    successHeader: 'File has been imported successfully',
    indivLicHeader: 'Individual or discontinued license option(s) found',
    downloadReport: 'Download Report',
  },
};

function lt(nm) {
  return ltext[window.app.lang][nm];
}

let finalReport = '';

export function ImportShieldFileResultDlg(report) {
  finalReport = report;
  // report=report.replace(/(?:\r\n|\r|\n)/g, '<br>');
  return html`
    <div style="padding:20px;">
      <h1>${lt('successHeader')}</h1>
      <ul>
        ${report.logs.map(logEntry => html` <li>${logEntry.message}</li>`)}
      </ul>
      <h1>${report.invalidLicenseOptions.length ? lt('indivLicHeader') : ''}</h1>
      <ul>
        ${report.invalidLicenseOptions.map(
          logEntry => html` <li>${logEntry.key}=${logEntry.value}</li>`,
        )}
      </ul>
      <div style="padding-top:20px;">
        ${report.logs.length || report.invalidLicenseOptions.length
          ? html` <wl-button @click="${save.bind(this)}">${lt('downloadReport')}</wl-button>`
          : ''}
      </div>
    </div>
  `;
}

function save() {
  const encodedReport = encodeURIComponent(finalReport);
  const content = `data:text/plain;charset=utf-8,` + buildPlaintextReport();
  const link = document.createElement('a');
  link.setAttribute('href', content);

  const date = new Date();
  const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  const formattedTime = `${date.getHours()}-${date.getMinutes()}-${date.getSeconds()}`;
  const filename = `shieldfile-export-${formattedDate}-${formattedTime}.txt`;

  link.setAttribute('download', filename);
  document.body.appendChild(link); // Required for FF
  link.click();
}

function buildPlaintextReport() {
  let text = lt('successHeader') + '\n';
  finalReport.logs.forEach(logEntry => {
    text += logEntry.message + '\n';
  });
  if (finalReport.invalidLicenseOptions.length) {
    text += '\n';
    text += lt('indivLicHeader') + '\n';
    finalReport.invalidLicenseOptions.forEach(logEntry => {
      text += logEntry.key + '=' + logEntry.value + '\n';
    });
  }
  return text;
}
