import { System } from '../System';
import { Bus } from '../Bus';

export function Import(newArch) {
  createArch(newArch);
  window.app.requestUpdate();
  return true;
}

function getSystem(pa) {
  const s = JSON.parse(pa);
  const sys = new System(s.name, s);
  return sys;
}

function getBus(b) {
  let bus = null;
  if (!b.partners) {
    return null;
  }
  const p = [];
  b.partners.map(pa => {
    p.push(getSystem(pa));
  });
  if (p.length > 0) {
    bus = new Bus();
    bus.addPartner(p[0]);
    bus.id = b.id;
    bus.num = b.num;
    bus.partners = p;
    p.map(pa => {
      pa.bus = bus;
    });
    if (b.busses) {
      bus.busses = [];
      b.busses.map(bu => {
        const bb = getBus(bu);

        if (bb) {
          bb.parentId = b.id;
          bus.busses.push(bb);
        }
      });
    }
    return bus;
  }
  return bus;
}

function createArch(a) {
  const ret = {};
  try {
    ret.busses = [];
    if (a.busses) {
      a.busses.map(b => {
        const bb = getBus(b);
        bb.num = b.num;
        if (bb) {
          ret.busses.push(bb);
        }
      });
    }
    ret.name = a.name;
    ret.oaVersion = window.app.architecture.oaVersion;
    window.app.architecture = ret;
    if (!window.app.architecture.license) {
      window.app.architecture.license = { sus: 'sus', license: 'lic' };
    }
  } catch (e) {
    window.app.error(e);
  }
}
