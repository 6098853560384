import { css, html, LitElement } from 'lit-element';

export class PageHelp extends LitElement {
  static get styles() {
    return css`
      .box > .title {
        font-weight: bold;
      }
      .box {
        margin-left: 12px;
        margin-bottom: 12px;
      }
      wl-button {
        --button-bg: var(--button-color);
      }
      wl-button.level {
        --button-bg: #aaa;
      }
    `;
  }

  static get properties() {
    return {
      title: { type: String },
      lang: { type: String },
    };
  }

  render() {
    if (window.app.lang === 'de') {
      return this.textDe();
    }
    return this.textEn();
  }

  textDe() {
    return html`
      <div style="padding:20px;">
        <h2>WinCC OA Konfigurator</h2>

        <p>
          Dieses Tool soll die Erstellung konsistenter Konfigurationen von WinCC OA Architekturen
          erleichtern.
        </p>

        <p style="color:red;">Für die optimale Darstellung wird Chrome empfohlen.</p>

        <h3>Bedienung</h3>

        <h4>Systemauswahl</h4>
        <img src="/media/SingleServer.svg" height="64px" style="float:left;padding:10px;" />
        <p style=";padding:15px;">Ein Klick auf das System Icon öffnet die Detailauswahl.</p>

        <div style="clear:both"></div>
        <h4>Architektur Details</h4>

        <div style="display:flex;">
          <div>
            <a href="/help/media/ArchDetail_de.png" target="_new"
              ><img src="/help/media/ArchDetail_de.png" height="250px"
            /></a>
          </div>
          <div style=";padding:15px;">
            <p>Hier kann der Systemtyp ausgewählt werden. Folgende Typen stehen zur Verfügung:</p>

            <ul>
              <li>WinCC OA System in Single oder redundanter Konfiguration</li>
              <li>Oracle database Server in Single oder redundanter Konfiguration</li>
              <li>Influx DB Server</li>
              <li>Remote Para</li>
            </ul>
            <p>
              Basierend auf dem Systemtyp kann der Rest der Detailinformationen eingegeben werden.
            </p>
          </div>
        </div>

        <h4>System hinzufügen</h4>

        <p>Auf jeder Systemebene werden zwei Icons zur Architekturanpassung angezeigt.</p>

        <wl-button fab style="float:left;margin:10px;">
          <wl-icon>add</wl-icon>
        </wl-button>
        <p>Fügt ein System auf gleicher Ebene als verteiltes System hinzu.</p>
        <div style="clear:both"></div>

        <h4>Gruppen</h4>
        <wl-button fab style="float:left;margin:10px;">
          <wl-icon>create_new_folder</wl-icon>
        </wl-button>
        <p>Legt eine neue Gruppe unterhalb des Systems an.</p>
        <div style="clear:both"></div>

        <div style="padding:30px;border:1px solid grey;margin:20px;">
          <wl-icon>priority_high</wl-icon>
          <strong
            >Speichern Sie Ihre Architektur, bevor Sie weitere Schritte im Konfigurator
            durchführen.</strong
          >
          <div class="button" style="margin:4px;">
            <wl-button>Architektur speichern</wl-button>
          </div>
        </div>

        <h4>BOM (Bill of Material)</h4>

        <p>Der Tab listet alle erforderlichen Lizenzen für alle konfigurierten Systeme auf.</p>

        <div style="display:flex;">
          <div>
            <a href="/help/media/BOMLicSUS_de.png" target="_new"
              ><img src="/help/media/BOMLicSUS_de.png" height="250px"
            /></a>
          </div>
          <div style="padding:20px;">
            <p>Hier kann ausgewählt werden:</p>
            <ul>
              <li>Lizenz oder</li>
              <li>One-Time Upgrade MLFBs</li>
            </ul>
            <p>und zusätzlich eines der gelisteten</p>
            <ul>
              <li>SUS</li>
              <li>SMS MLFBs</li>
            </ul>
          </div>
        </div>

        <p>
          Oracle Lizenzen werden geringfügig anders behandelt: Da die Oracle Lizenzen immer das
          erste Jahr Wartung inkludieren, wird die Oracle Wartung (SUS) nur angezeigt, wenn "keine
          Lizenzen" und "SUS" ausgewählt sind.
        </p>

        <p>
          Export MALL und Export CSV ermöglichen den Export der Stückliste in einem Format, das in
          der Siemens Industry Mall importiert werden kann.
        </p>

        <h4>Export</h4>

        <h5>Verfügbare Export-Methoden</h5>
        <div class="box">
          <div class="title">Industry Mall (XML)</div>
          <div class="description">
            Verwenden Sie das XML-File für den Import in den Siemens Mall Einkaufswagen.
          </div>
        </div>

        <div class="box">
          <div class="title">Architektur speichern</div>
          <div class="description">
            Speichern Sie die entworfene Architektur zur späteren Wiederverwendung im WINCC OA
            Konfigurator. Bitte verwenden Sie dieses Format für die Kommunikation.
          </div>
        </div>

        <div class="box">
          <div class="title">Lesbares Format (HTML)</div>
          <div class="description">
            Exportieren Sie die Stückliste in einem für den Menschen lesbaren, tabellarischen
            Format.
          </div>
        </div>

        <div class="box">
          <div class="title">CSV Format für internes Vertriebs Tool</div>
          <div class="description">
            Spezielles CSV-Format nur zur Verwendung im Excel-Rechner für den Vertrieb. Nach dem
            Export nicht mehr verändern. Bitte verwenden Sie bei der Kommunikation immer
            "Architektur speichern"!
          </div>
        </div>
      </div>
    `;
  }

  textEn() {
    return html`
      <div style="padding:20px;">
        <h2>WinCC OA Configurator</h2>

        <p>
          This tool is intended to support you in generating WinCC OA configurations in a consistent
          way.
        </p>

        <p style="color:red;">For optimal experience usage of Chrome is recommended.</p>
        <h3>Basic operation (Plant Architecture)</h3>

        <h4>choose System</h4>
        <img src="/media/SingleServer.svg" height="64px" style="float:left;padding:10px;" />
        <p style=";padding:15px;">
          Clicking on a system icon will open the detail view of this system.
        </p>

        <div style="clear:both"></div>
        <h4>Architecture Details</h4>

        <div style="display:flex;">
          <div>
            <a href="/help/media/ArchDetail_en.png" target="_new"
              ><img src="/help/media/ArchDetail_en.png" height="250px"
            /></a>
          </div>
          <div style=";padding:15px;">
            <p>You can choose the system type of the specific system. It can be of type</p>

            <ul>
              <li>WinCC OA System or redundant configuration</li>
              <li>Oracle database server or redundant configuration</li>
              <li>Influx DB server</li>
              <li>Remote Para</li>
            </ul>
            <p>Based on the chosen type, the rest of the details can be chosen.</p>
          </div>
        </div>

        <h4>Add System</h4>

        <p>
          On each system level you will find icons on the left side for changing the architecture.
        </p>

        <wl-button fab style="float:left;margin:10px;">
          <wl-icon>add</wl-icon>
        </wl-button>
        <p>You can add a system on the same level as a distributed system.</p>
        <div style="clear:both"></div>

        <h4>Groups</h4>
        <wl-button fab style="float:left;margin:10px;">
          <wl-icon>create_new_folder</wl-icon>
        </wl-button>
        <p>Here you can create a new group below the system.</p>
        <div style="clear:both"></div>

        <div style="padding:30px;border:1px solid grey;margin:20px;">
          <wl-icon>priority_high</wl-icon>
          <strong
            >Save your architecture before you carry out further steps in the configurator.</strong
          >
          <div class="button" style="margin:4px;">
            <wl-button>Export Architecture</wl-button>
          </div>
        </div>
        <h3>BOM (Bill of Material)</h3>

        <p>Choosing the View BOM lists all the licenses needed for the configured systems.</p>

        <div style="display:flex;">
          <div>
            <a href="/help/media/BOMLicSUS_en.png" target="_new"
              ><img src="/help/media/BOMLicSUS_en.png" height="250px"
            /></a>
          </div>
          <div style="padding:20px;">
            <p>You can choose, if you want to see:</p>
            <ul>
              <li>Licence</li>
              <li>One Time Upgrade MLFBs</li>
            </ul>
            <p>and add</p>
            <ul>
              <li>SUS</li>
              <li>SMS MLFBs</li>
            </ul>
          </div>
        </div>

        <p>
          Oracle licenses will be handled slightly different as the Oracle License will always
          include the first year of maintenance, Oracle Maintenance will only be shown if "no
          Licenses" and "SUS" is chosen.
        </p>

        <h3>Export</h3>

        <p>
          Industry Mall (XML) exports the BOM in a format, that can be imported in the Siemens
          Industry Mall.
        </p>

        <h4>Types of available export methods</h4>
        <div class="box">
          <div class="title">Industry Mall (XML)</div>
          <div class="description">
            Use the XML for importing into the Siemens Mall shopping cart.
          </div>
        </div>

        <div class="box">
          <div class="title">Save Architecture</div>
          <div class="description">
            Save the designed architecture for later reuse in the WINCC OA configurator. Pleaes use
            this format for communication.
          </div>
        </div>

        <div class="box">
          <div class="title">Human readable table (HTML)</div>
          <div class="description">
            Export the bill of material in human-readable tabular format
          </div>
        </div>

        <div class="box">
          <div class="title">Internal Sales Calculator (CSV)</div>
          <div class="description">
            Special CSV format for usage in sales excel calculator only. Do not modify after export.
            When communicating please always use "Save Architecture"!
          </div>
        </div>
      </div>
    `;
  }
}
