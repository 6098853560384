import { html } from 'lit-html';

let lang = window.app ? window.app.lang : navigator.language.substring(0, 2);
if (lang !== 'de' && lang !== 'en') {
  lang = 'en';
}

export const BrowserWarning =
  lang === 'en'
    ? html`
        <div style="padding:30px;">
          <h2>Browser Support</h2>
          <p>For best user experience we suggest to use Google Chrome.</p>
        </div>
      `
    : html`
        <div style="padding:30px;">
          <h2>Browser-Support</h2>
          <p>Die Benutzung von Google Chrome als Browser wird empfohlen.</p>
        </div>
      `;
