import { createPopper } from '@popperjs/core';
import { css, html, LitElement } from 'lit-element';
import { showDialog } from 'weightless';
import { templateAbout } from './templateAbout.js';

export class F3nHeader extends LitElement {
  static get styles() {
    return css`
      #topMenu {
        z-index: 999;
        box-shadow: 0 0 6px 3px rgba(60, 69, 97, 0.2);
        background-color: white;
        display: none;
        border: 1px solid grey;
        padding: 10px;
        width: 400px;
        height: 268px;

        font-size: 13px;
      }
      #topMenu[data-show] {
        display: block;
      }
      #topMenu[data-popper-placement^='top'] #arrow {
        bottom: -4px;
      }

      #topMenu[data-popper-placement^='bottom'] #arrow {
        top: -21px;
      }

      #topMenu[data-popper-placement^='left'] #arrow {
        right: -4px;
      }

      #topMenu[data-popper-placement^='right'] #arrow {
        left: -4px;
      }
      #arrow,
      #arrow::before {
        border: unset;
        background: unset;
        position: absolute;
        width: 40px;
        height: 40px;
        left: 345px;
        z-index: -1;
      }

      #arrow::before {
        content: '';
        border-left: 1px solid grey;
        border-top: 1px solid grey;

        transform: rotate(45deg);
        background: white;
      }

      div {
        height: 54px;
        background-color: #dfe3ee;
        color: #3a435e;
        border-bottom: 1px solid #dfe0e5;
        margin: 0px;
      }

      div h2 {
        margin: 0;
        padding: 0;
        padding-top: 11px;
      }

      @media (min-width: 48em) {
        div {
          left: 120px;
          padding-left: 3px;
        }
      }

      @media (max-width: 48em) {
        div {
          left: 0;
          padding-left: 48px;
        }
      }
      .settings-select {
        height: 50px;
        width: 100%;
        border: 1px solid #b8b8b8;
        background: #f1f1f1;
      }
    `;
  }

  static get properties() {
    return {
      project: { type: String },
    };
  }

  constructor() {
    super();
    this.title = '';
    this.lang = '';
    this.project = '';
    this.usedPackages = {};
    window.process = { env: { NODE_ENV: 'production' } };
  }

  firstUpdated() {
    this.ele_project = this.shadowRoot.getElementById('project');
    createPopper(
      this.shadowRoot.querySelector('#settingsIcon'),
      this.shadowRoot.querySelector('#topMenu'),
      {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [-370, 20],
            },
          },
        ],
        placement: 'bottom',
      },
    );

    // select current language
    this.shadowRoot.querySelector('#language-select').value = this.lang;
  }

  showtopMenu() {
    this.shadowRoot.querySelector('#topMenu').setAttribute('data-show', '');
  }

  hidetopMenu() {
    this.shadowRoot.querySelector('#topMenu').removeAttribute('data-show');
  }

  toggleTopMenu() {
    if (this.shadowRoot.querySelector('#topMenu').hasAttribute('data-show')) {
      this.hidetopMenu();
    } else {
      this.showtopMenu();
    }
  }

  changeLang(e) {
    this.dispatchEvent(new CustomEvent('lang-change', { detail: { lang: e.target.value } }));
    this.hidetopMenu();
  }

  redirectTo(target) {
    document.location.href = target;
  }

  changeVersion(e) {
    if (window.app.architecture.initial === false) {
      if (window.confirm(this.lt('confirmOverride'))) {
        const redirectTarget =
          e.target.value === '3.2' ? '/index.html' : `/${e.target.value}/index.html`;
        this.redirectTo(redirectTarget);
      } else {
        e.target.value = window.app.architecture.oaVersion;
      }
    } else {
      const redirectTarget =
        e.target.value === '3.2' ? '/index.html' : `/${e.target.value}/index.html`;
      this.redirectTo(redirectTarget);
    }
  }

  async showInfoPopup() {
    await showDialog({
      fixed: true,
      backdrop: true,
      blockScrolling: true,
      container: document.body,
      size: 'large',
      duration: 200,
      template: templateAbout(),
    });
  }

  lt(cd) {
    let tx = {
      de: {
        title: 'WinCC OA Konfigurator 3.20',
        chooseversion: 'Version',
        confirmOverride:
          'ACHTUNG! Dadurch wird die bestehende Konfiguration überschrieben. Möchten Sie fortfahren?',
      },
      en: {
        title: 'WinCC OA Configurator 3.20',
        chooseversion: 'Version',
        confirmOverride:
          'CAUTION! This will override the existing Configuration. Do you want to proceed?',
      },
    };

    return tx[window.app.lang][cd];
  }

  render() {
    return html`
      <div id="topBar" style="display:flex">
        <div style="flex:15">
          <h4 style="margin-block-start: 18px;">
            ${this.lt('title')} ${!!this.project ? '' : 'Proj. ' + this.project}
          </h4>
        </div>

        <div style="flex:1;justify-content: right;display:flex;">
          <div
            @click="${this.showInfoPopup}"
            id="infoIcon"
            style="background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20width%3D%2724%27%20height%3D%2724%27%20viewBox%3D%270%200%2024%2024%27%3E%3Cpath%20fill-rule%3D%27evenodd%27%20clip-rule%3D%27evenodd%27%20fill%3D%27%233A435E%27%20d%3D%27M12%209a2%202%200%201%200-.001-4.001A2%202%200%200%200%2012%209zm1.5%201h-4v2h1v5H9v2h6v-2h-1.5v-7zM12%201C5.925%201%201%205.925%201%2012s4.925%2011%2011%2011%2011-4.925%2011-11S18.075%201%2012%201zm0%2021C6.477%2022%202%2017.523%202%2012S6.477%202%2012%202c5.522%200%2010%204.477%2010%2010s-4.478%2010-10%2010z%27%2F%3E%3C%2Fsvg%3E'); background-repeat: no-repeat; width: 32px; height: 32px;margin-top:12px;"
          ></div>
          <div
            @click="${this.toggleTopMenu}"
            id="settingsIcon"
            style="background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20width%3D%2724%27%20height%3D%2724%27%20viewBox%3D%270%200%2024%2024%27%3E%3Cpath%20fill-rule%3D%27evenodd%27%20clip-rule%3D%27evenodd%27%20fill%3D%27%233A435E%27%20d%3D%27M22.986%2010.496l.023%203.167-2.789.382a8.319%208.319%200%200%201-.998%202.386l1.764%202.336-2.23%202.223-2.344-1.76a8.24%208.24%200%200%201-2.379.977L13.65%2023l-3.175-.023-.425-2.811a8.483%208.483%200%200%201-2.39-1.022l-2.237%201.659-2.261-2.256%201.67-2.239a8.602%208.602%200%200%201-1.02-2.378L1%2013.504l.008-3.167%202.761-.379a8.322%208.322%200%200%201%20.97-2.374L2.968%205.238l2.229-2.224%202.328%201.748a8.215%208.215%200%200%201%202.412-1.01l.38-2.764%203.175.023.418%202.76c.881.215%201.697.58%202.447%201.044l2.234-1.66%202.26%202.256-1.674%202.241a8.512%208.512%200%200%201%201.027%202.424l2.782.42zM11.95%206.94a4.957%204.957%200%200%200-5.013%205.002c.02%202.782%202.297%205.054%205.086%205.074%202.79.02%205.034-2.22%205.014-5.002S14.738%206.96%2011.95%206.94z%27%2F%3E%3C%2Fsvg%3E'); background-repeat: no-repeat; width: 32px; height: 32px;margin-top:12px;"
          ></div>
        </div>
      </div>
      <div id="topMenu" role="topMenu">
        <div style="padding:30px;background-color:unset;border:unset;">
          <p>
            <strong
              ><span style="color:black;font-weight:700;font-size:initial"
                >Bitte wählen Sie Ihre Sprache / Please choose your language.</span
              ></strong
            >
          </p>
          <wl-select @change="${this.changeLang}" class="" id="language-select">
            <option value="de">Deutsch</option>
            <option value="en">English</option>
          </wl-select>

          <p>
            <strong
              ><span style="color:black;font-weight:700;font-size:initial"
                >${this.lt('chooseversion')}</span
              ></strong
            >
          </p>
          <wl-select
            value="${window.app.architecture.oaVersion}"
            @change="${this.changeVersion}"
            class=""
            id="version-select"
          >
            <option value="3.2">3.20</option>
            <option value="3.19">3.19</option>
            <option value="3.18">3.18</option>
          </wl-select>

          <div id="arrow" data-popper-arrow></div>
        </div>
      </div>
    `;
  }
}
