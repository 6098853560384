import { css, html, LitElement } from 'lit-element';

export class SelectSectionShieldFile extends LitElement {
  static get styles() {
    return css`
      :host {
        margin: 12px;
      }

      .sections {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }

      wl-button.startimport {
        margin-top: 8px;
      }
    `;
  }

  static get properties() {
    return {
      sections: { type: Object },
      currentSection: { type: Object },

      name: String,
    };
  }

  constructor() {
    super();

    this.ref = null;
    this.sections = [];
    this.name = '';
    this.arch = null;
    this.currentSection = null;
  }

  lt(cd) {
    const tx = {
      de: {
        header: 'Mehrere Sektionen',
        text: 'Diese Datei enthält mehrere Sektionen. Bitte wählen Sie die zu importierende Sektion',
        import_section: 'Sektion importieren',
        start_import: 'Shield-Datei importieren',
      },
      en: {
        header: 'Multiple Sections',
        text: 'This file contains several sections. Please select the section you want to import',
        import_section: 'Import Section',
        start_import: 'Import Shield-File',
      },
    };

    return tx[window.app.lang][cd];
  }

  render() {
    return html`
      <h1>${this.lt('header')}</h1>
      <p>${this.lt('text')}</p>
      <div class="sections">
        ${this.sections.map(section => {
          const entry = this.getEntryByName('sn', section.items);
          if (entry === undefined) {
            return;
          }
          return html`
            <wl-label>
              <wl-radio
                name="section_name"
                value="${entry.value}"
                @change="${this.setCurrentSection.bind(this, { section: section })}"
                id="section_name"
              ></wl-radio>
              ${entry.value}
            </wl-label>
          `;
        })}
      </div>
      <wl-button
        ?disabled=${this.currentSection === null}
        class="startimport"
        @click="${this.onStartImportClick.bind(this)}"
      >
        ${this.lt('start_import')}
      </wl-button>
    `;
  }

  setCurrentSection(e) {
    this.currentSection = e.section;
  }

  getEntryByName(name, itemList) {
    return itemList.find(e => e.name === name);
  }

  onStartImportClick() {
    this.dispatchEvent(
      new CustomEvent('section-selected', {
        detail: {
          section: this.currentSection,
        },
      }),
    );
  }
}
