import { css, html, LitElement } from 'lit-element';
import { classMap } from 'lit-html/directives/class-map.js';

import '../../page-main/page-main.js';
import '../../page-one/page-one.js';
import '../../page-start/page-start.js';
import '../../page-help/page-help.js';
import '../../page-export/page-export.js';
import '../f3n-vmenu.js';
import '../f3n-header.js';
import { showDialog } from 'weightless';
import { ViewportSizeWarning } from './ViewportSizeWarning.js';
import { detect } from 'detect-browser';
import { BrowserWarning } from './BrowserWarning';

export class OaConfig extends LitElement {
  static get properties() {
    return {
      title: { type: String },
      page: { type: String },
      lang: { type: String },
    };
  }

  static get styles() {
    return css`
      --host {
        min-height: 100vh;
        --display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        font-size: calc(10px + 2vmin);
        color: #1a2b42;
        max-width: 1920px;
        margin: 0 auto;
        --f3n-vmenu-background-color: #3a435e;
        --button-color: #3a435e;
        --button-bg: #3a435e;
        --f3n-vmenu-color: #fff;
        --f3n-vmenu-select-color: #ff8000;
        --f3n-vmenu-icon-size: 32;
        --f3n-vmenu-width: 120px;
      }

      #layout {
        position: relative;
        left: 0;
        padding-left: 0;
        height: 100%;
        width: 100%;
      }

      #langs {
        position: fixed;
        z-index: 1001;
        bottom: 54px;
        left: 0;
        width: 120px;
        padding-right: 10px;
      }

      @media (min-width: 48em) {
        #layout {
          padding-left: var(--f3n-vmenu-width, 120px); /* left col width "#menu" */
          width: calc(100% - var(--f3n-vmenu-width, 120px));
          left: 0;
        }

        #layout.active {
          left: var(--f3n-vmenu-width, 120px);
        }
      }

      @media (max-width: 48em) {
        #layout.active {
          position: relative;
          left: var(--f3n-vmenu-width, 120px);
        }
        f3n-vmenu {
          position: fixed;
          z-index: 1000;
          top: 0;
          left: 0;
        }
        #langs {
          display: none;
        }
      }

      main {
        --width: calc(100% - 20px);
        --width: 100%;
        --height: 100%;
        flex-grow: 1;
      }
    `;
  }

  constructor() {
    super();
    this.page = 'start';
    this.architecture = { oaVersion: 3.2, busses: [] };
    this.pricelist = {};
    this.lang = navigator.language.substring(0, 2);
    if (['de', 'en'].indexOf(this.lang) === -1) {
      this.lang = 'en';
    }
    window.app = this;
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    if (vw < 700) {
      this.showViewportSizeWarning();
    }

    const browser = detect();
    if (browser.name !== 'chrome') {
      showDialog({
        fixed: true,
        backdrop: true,
        blockScrolling: true,
        container: document.body,
        size: 'small',
        duration: 200,
        template: BrowserWarning,
      });
    }

    if (!window.app.architecture.license) {
      window.app.architecture.license = { sus: 'sus', license: 'lic' };
    }
  }

  firstUpdated() {
    const _this = this;
    Papa.parse('../../data/pl_WinCC_OA_V320.csv?date=' + Date.now(), {
      download: true,
      step(row) {
        if (row.data[0] && row.data[0].startsWith('6AV')) {
          _this.pricelist[row.data[0]] = row.data;
        }
      },
    });
    this.ele_menu = this.shadowRoot.getElementById('menu');
    this.ele_header = this.shadowRoot.getElementById('header');
  }

  lt(nm) {
    const ltext = {
      de: {
        start: 'Willkommen',
        about: 'Über',
        help: 'Hilfe',
        arch: 'Architektur',
        bom: 'Stückliste',
        expo: 'Exportieren',
        title: 'WinCC OA Konfigurator',
      },
      en: {
        start: 'Welcome',
        about: 'About',
        help: 'Help',
        arch: 'Architecture',
        bom: 'Bill of Material',
        expo: 'Export',
        title: 'WinCC OA Configuration',
      },
    };
    return ltext[window.app.lang][nm];
  }

  pageSelected(e) {
    this.page = e.detail.page;
  }

  changeLang(e) {
    window.app.lang = e.detail.lang;
    this.lang = window.app.lang;
    // update topbar
    this.shadowRoot.querySelector('#header').requestUpdate();
  }

  render() {
    if (!this.menu) {
      this.menu = [
        { icon: 'welcome', label: this.lt('start'), divided: false, selected: true, page: 'start' },
        { icon: 'arch', label: this.lt('arch'), divided: false, selected: false, page: 'main' },
        {
          icon: 'cataloque',
          label: this.lt('bom'),
          divided: false,
          selected: false,
          page: 'pageOne',
        },
        {
          icon: 'export',
          label: this.lt('expo'),
          divided: false,
          selected: false,
          page: 'pageExport',
        },
        { icon: 'manual', label: this.lt('help'), divided: false, selected: false, page: 'help' },
      ];
    }
    this.menu[0].label = this.lt('start');
    this.menu[1].label = this.lt('arch');
    this.menu[2].label = this.lt('bom');
    this.menu[3].label = this.lt('expo');
    this.menu[4].label = this.lt('help');

    if (this.ele_menu) {
      this.ele_menu.requestUpdate();
    }
    return html`
      <div id="layout">
        <f3n-vmenu id="menu" .menu=${this.menu} @menu-selected="${this.pageSelected.bind(this)}">
        </f3n-vmenu>
        <f3n-header
          lang="${this.lang}"
          id="header"
          @lang-change="${this.changeLang}"
          @nav-change="${this.navChange}"
          project="${window.app.architecture.name}"
        ></f3n-header>
        <main>${this._renderPage()}</main>
      </div>
    `;
  }

  navChange(e) {
    this.page = e.detail.navigateTo;
  }

  _renderPage() {
    switch (this.page) {
      case 'start':
        return html`
          <page-start lang="${this.lang}" @nav-change="${this.navChange}"></page-start>
        `;
      case 'main':
        return html` <page-main lang="${this.lang}"></page-main> `;
      case 'pageOne':
        return html` <page-one lang="${this.lang}"></page-one> `;
      case 'pageExport':
        return html` <page-export lang="${this.lang}"></page-export> `;
      case 'help':
        return html` <page-help lang="${this.lang}"></page-help> `;
      default:
        return html` <p>Page not found try going to <a href="#main">Main</a></p> `;
    }
  }

  __onNavClicked(ev) {
    ev.preventDefault();
    this.page = ev.target.hash.substring(1);
  }

  __navClass(page) {
    return classMap({ active: this.page === page });
  }

  error(err) {
    // TODO: show the error
    console.log(err);
  }

  showViewportSizeWarning() {
    showDialog({
      fixed: true,
      backdrop: true,
      blockScrolling: true,
      container: document.body,
      size: 'small',
      duration: 200,
      template: ViewportSizeWarning,
    });
  }
}
