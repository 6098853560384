import { css, html, LitElement } from 'lit-element';
import { save } from '../../export';
import { getAmountOfSections, getEntry, loadIniFile, parseShieldFile } from './ImportShieldFile';
import { showDialog } from 'weightless';
import { ImportShieldFileResultDlg } from './ImportShieldFileResultDlg';
import { Import } from '../../import';

export class PageMain extends LitElement {
  constructor() {
    super();
    this.title =
      window.app.lang === 'de' ? 'WinCC OA Konfigurator 3.18' : 'WinCC OA Configurator 3.18';
    this.lang = '';
    this.architecture = {};
  }

  static get styles() {
    return css`
      :host {
        display: block;
        padding: 25px;
        position: relative;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        --button-bg: #3a435e;
        --height: 100%;
        --width: 100%;
      }

      #master {
        height: 100%;
        width: 100%;
      }

      .box2 {
        float: left;
        width: calc(100% - 40px);
        padding: 20px;
      }

      arch-list {
        width: 100%;
        flex-direction: row;
      }

      arch-detail {
        float: left;
        flex-direction: row;
        width: 100%;
      }
    `;
  }

  static get properties() {
    return {
      title: { type: String },
      lang: { type: String },
    };
  }

  lt(nm) {
    const ltext = {
      de: {
        save: 'Architektur exportieren',
        load: 'Architektur laden',
        load_shieldfile: 'Shield-Datei laden',
        proj: 'Projekt: ',
        iniParseError: 'Shield file kann nicht geladen werden, Ungültiges Format',
        configuration_has_been_loaded: 'Konfiguration wurde erfolgreich geladen',
        unable_to_parse_json: 'Datei kann nicht gelesen werden',
        confirm_override:
          'ACHTUNG! Dadurch wird die bestehende Konfiguration überschrieben. Möchten Sie fortfahren?',
        multiple_sections_not_supported:
          'Das Shield-File enthält mehrere Lizenzen. Es kann nur eine Lizenz pro File importiert werden. Bitte den gewünschten Abschnitt [license] aus dem Shield-File in ein eigenes File übertragen und erneut laden.',
        incompatible_oa_version:
          'Diese Datei ist für WinCCOA %v. Möchten Sie zum Konfigurator für WinCCOA %v wechseln?',

        no_support_for_temp_license: 'Der Import von temporären Lizenzen wird nicht unterstützt',
        no_support_for_demo_license: 'Der Import von Demo-Lizenzen wird nicht unterstützt',
      },
      en: {
        save: 'Export Architecture',
        load: 'Load Architecture',
        load_shieldfile: 'Load Shield-File',
        proj: 'Project: ',
        iniParseError: 'Unable to load shield file. Invalid format',
        configuration_has_been_loaded: 'Configuration has been loaded',
        unable_to_parse_json: 'Unable to read file',
        confirm_override:
          'CAUTION! This will override the existing Configuration. Do you want to proceed?',
        multiple_sections_not_supported:
          'The Shield file contains several licenses. Only one license can be imported per file. Please transfer the desired section [license] from the shield file into a separate file and load it again.',
        incompatible_oa_version:
          'This File is for WinCCOA %v. Do you want to be redirected to the Configurator for WinCCOA %v?',
        no_support_for_temp_license: 'No support for temporarily licenses',
        no_support_for_demo_license: 'No support for demo-licenses',
      },
    };
    return ltext[window.app.lang][nm];
  }

  render() {
    return html`
      <wl-dialog id="select-section-shieldfile" size="small" fixed backdrop blockscrolling>
        <select-section-shieldfile
          @section-selected="${this.onSectionSelected}"
        ></select-section-shieldfile>
      </wl-dialog>

      <wl-dialog id="dialog" fixed backdrop blockscrolling>
        <arch-list lang="${this.lang}" @close="${this.closeArchDialog}"></arch-list>
      </wl-dialog>

      <div style="padding-top:20px;">
        <h2 style="display:inline;">${window.app.architecture.name}</h2>
        <div style="display:inline; float:right; padding-right:10px;">
          <wl-button fab @click="${this.architecturePopup}">
            <wl-icon>device_hub</wl-icon>
          </wl-button>
        </div>
      </div>
      <div id="master">
        <div class="box2">
          <arch-detail id="archdetail"></arch-detail>
        </div>
      </div>
      <div style="position:fixed; bottom:5px; right:5px;">
        <wl-button @click="${save.bind(this)}">${this.lt('save')}</wl-button>

        <wl-button @click="${this.load.bind(this)}">${this.lt('load')}</wl-button>
        <wl-button @click="${this.loadShieldFile.bind(this)}"
          >${this.lt('load_shieldfile')}</wl-button
        >
      </div>
    `;
  }

  firstUpdated() {
    if (window.app && window.app.architecture && window.app.architecture.initial) {
      // window.app.architecture.initial = false;
    }
  }

  changeLangToDe(e) {
    window.app.lang = 'de';
    this.title = 'WinCC OA Konfigurator';
    this.updateArchitecture();
    this.requestUpdate();
  }

  changeLangToEn(e) {
    window.app.lang = 'en';
    this.title = 'WinCC OA Configurator';
    this.updateArchitecture();
    this.requestUpdate();
  }

  closeArchDialog() {
    this.shadowRoot.querySelector('#dialog').hide();
    // update architecture
    this.updateArchitecture();
  }

  updateArchitecture() {
    this.shadowRoot.querySelector('#archdetail').requestUpdate();
  }

  architecturePopup(e) {
    this.shadowRoot
      .querySelector('#dialog')
      .show({ size: 'large' })
      .then(dlg => {
        this.requestUpdate();
      });
  }

  load() {
    const that = this;

    if (
      window.app.architecture.initial === false &&
      window.confirm(this.lt('confirm_override')) === false
    ) {
      return;
    }
    const readFile = function (e) {
      const file = e.target.files[0];
      if (!file) {
        return;
      }
      const reader = new FileReader();
      reader.onload = function (e) {
        let a;
        try {
          a = JSON.parse(e.target.result);
          const targetPlatform = typeof a.oaVersion !== 'undefined' ? a.oaVersion : 3.2;

          if (targetPlatform !== window.app.architecture.oaVersion) {
            if (
              window.confirm(that.lt('incompatible_oa_version').replaceAll('%v', targetPlatform))
            ) {
              that.redirectTo(
                targetPlatform.toString() === '3.20'
                  ? '/index.html'
                  : `/${targetPlatform}/index.html`,
              );
            }
            return false;
          }

          Import(a);
          that.shadowRoot.getElementById('archdetail').requestUpdate();
          that.requestUpdate();
          alert(that.lt('configuration_has_been_loaded'));

          document.body.removeChild(fileInput);
        } catch (err) {
          window.app.error(err);
          alert(that.lt('unable_to_parse_json'));
          return false;
        }
      };
      reader.readAsText(file);
    };
    let fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = '.json';
    fileInput.style.display = 'none';
    fileInput.onchange = readFile;
    document.body.appendChild(fileInput);
    fileInput.click();
  }

  loadShieldFile() {
    const that = this;
    if (
      window.app.architecture.initial === false &&
      window.confirm(this.lt('confirm_override')) === false
    ) {
      return;
    }
    const readFile = function (e) {
      const file = e.target.files[0];
      if (!file) {
        return;
      }
      const reader = new FileReader();
      reader.onload = function (e) {
        const sections = loadIniFile(e.target.result);
        if (sections === false) {
          alert(that.lt('iniParseError'));
        }

        //abort if the expire entry exists
        const expireEntry = getEntry('expire', null);
        const demoPeriod = getEntry('demoPeriod', null);
        if (demoPeriod || (expireEntry && expireEntry.value !== '0000.00.00;00:00:00,000')) {
          alert(that.lt('no_support_for_temp_license'));
          return false;
        }

        const codeLine = getEntry('code', null);
        if (codeLine.value.toLowerCase().includes('demo')) {
          alert(that.lt('no_support_for_demo_license'));
          return false;
        }

        this.sections = sections;
        //let user select section if ini-file has more than 1 section
        if (getAmountOfSections(e.target.result) > 1) {
          const sfDialog = that.shadowRoot.querySelector('#select-section-shieldfile');
          sfDialog.children[0].sections = this.sections;
          sfDialog.show({ size: 'large' }).then(dlg => {});

          return false;
        } else {
          that.importShieldFile(this.sections[0]);
          document.body.removeChild(fileInput);
        }
      };
      reader.readAsText(file);
    };
    let fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = '.txt';
    fileInput.style.display = 'none';
    fileInput.onchange = readFile;
    document.body.appendChild(fileInput);
    fileInput.click();
  }

  onSectionSelected(e) {
    const sfDialog = this.shadowRoot.querySelector('#select-section-shieldfile');

    sfDialog.hide();
    this.importShieldFile(e.detail.section);
  }

  importShieldFile(section) {
    const parseResult = parseShieldFile(section);
    this.shadowRoot.getElementById('archdetail').requestUpdate();

    showDialog({
      fixed: true,
      backdrop: true,
      blockScrolling: true,
      container: document.body,
      size: 'small',
      duration: 200,
      template: ImportShieldFileResultDlg(parseResult),
    }).then(dlg => {});
  }

  redirectTo(target) {
    document.location.href = target;
  }

  toFileName(nm) {
    if (!nm) {
      return null;
    }
    let nam = `${nm}`;
    nam = nam.toLowerCase();
    nam = nam.replace(/\s/g, '_');
    return nam;
  }
}
